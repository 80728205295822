import { SEARCH_RESULTS_LOADED } from "../../constants/index";

/**
 * @param {string} text text to be searched
 */
const search = text => {
    return { 
        type: SEARCH_RESULTS_LOADED, 
        payload: [
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
            "ciao1",
        ] 
    }
}


export {
    search,
};