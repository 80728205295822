import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import API from "./../components/utils/API/api";
import Modal from './../components/modal/Modal'

import HeaderController from './header/HeaderController';
import MainController from './home/main/MainController';
import SearchResultsController from './searchResults/SearchResultsController';
import ProfilePageController from './profilePage/ProfilePageController';
import SurveysPageController from './surveysPage/SurveysPageController';
import PerformancesPageController from './performancePage/PerformancePageController';
import LoginController from './Login/LoginController';

import { connect } from "react-redux";
import { getLayout } from "../actions/layout";
import { Route, Switch, Redirect } from "react-router-dom";
import ProfilePageEditController from "./profilePage/ProfilePageEditController";
import NewsDetailController from "./NewsDetail/NewsDetailController";
import BullettinDetailController from "./BullettinDetail/BullettinDetailController";
import SurveysDetailPageController from "./surveysPage/SurveysDetailPageController";
import ModalController from "./Modal/ModalController";




const mapDispatchToProps = dispatch => {
  return {
    getLayout: type => dispatch(getLayout(type))
  };
}

const App = (props, context) => {

  let [isProfileCompleted, setIsProfileCompleted] = useState(0);
  //let isProfileCompleted = true;
  let token = localStorage.getItem('auth-token');

  props.getLayout(process.env.REACT_APP_TYPE);

  useEffect(() => {
    console.log("First App")
    async function fetchData() {
      try {
        const userMe = await API.get("/wp/v2/users/me");
        const userData = userMe.data;
        console.log("User Data:", userData);
        if(
          userData.acf.user_name_lbspa === "" ||
          userData.acf.user_surname_lbspa === "" ||
          userData.acf.user_personal_email_lbspa === ""
          /*
          userData.acf.user_country_lbspa === "" ||
          userData.acf.user_region_lbspa === "" ||
          userData.acf.user_city_lbspa === "" ||
          userData.acf.user_gender_lbspa === "" ||
          userData.acf.user_nationality_lbspa === "" ||
          userData.acf.user_birthdate_lbspa === "" ||
          userData.acf.user_linkedin_lbspa === "" ||
          userData.acf.user_skype_lbspa === ""
          */
        ) {
          setIsProfileCompleted(isProfileCompleted = false);
        }
        console.log("User Data Completed:", isProfileCompleted);
      } catch(error) {
        localStorage.removeItem('auth-token');
        token = null;
        API.setToken(null);
        console.log("Error:", error);
      }
    }
    if(token !== null) {
      fetchData();
    }
  });

  function changeToken(returnToken) {
    token = returnToken;
  }

  async function checkUserComplete() {
    setIsProfileCompleted(isProfileCompleted = true);
    try {
      const userMe = await API.get("/wp/v2/users/me");
      const userData = userMe.data;
      if(
        userData.acf.user_name_lbspa === "" ||
        userData.acf.user_surname_lbspa === "" ||
        userData.acf.user_personal_email_lbspa === ""
        /*
        userData.acf.user_country_lbspa === "" ||
        userData.acf.user_region_lbspa === "" ||
        userData.acf.user_city_lbspa === "" ||
        userData.acf.user_gender_lbspa === "" ||
        userData.acf.user_nationality_lbspa === "" ||
        userData.acf.user_birthdate_lbspa === "" ||
        userData.acf.user_linkedin_lbspa === "" ||
        userData.acf.user_skype_lbspa === ""
        */
      ) {
        setIsProfileCompleted(isProfileCompleted = false);
      }
    } catch(error) {
      console.log("Error:", error);
    }
    return isProfileCompleted;
  }

  return (
    <React.Fragment>
      <Switch>
        {/* TODO : <Route path="/signin"  render={ props => { return (<Init history={props.history}/>)}}/> */}

        <Route exact path="/" render={props => {
            return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <MainController history={props.history} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <MainController history={props.history} />
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/login" render={props => {
          return (
            <React.Fragment>
              <LoginController history={props.history} changeToken={changeToken} checkUserComplete={checkUserComplete}/>
            </React.Fragment>
          )
        }} />
        <Route exact path="/search" render={props => {
            return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <SearchResultsController history={props.history} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <SearchResultsController history={props.history} />
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/performance" render={props => {
          return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <PerformancesPageController history={props.history} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <PerformancesPageController history={props.history} />
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/surveys" render={props => {
          return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <SurveysPageController history={props.history} />
                    </React.Fragment>
                    ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <SurveysPageController history={props.history} />
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/profile" render={props => {
          return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <ProfilePageController history={props.history} changeToken={changeToken}/>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <ProfilePageController history={props.history} changeToken={changeToken}/>
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/profileEdit" render={props => {
          return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  isProfileCompleted === false ? (
                    <React.Fragment>
                      <Modal history={props.history} checkUserComplete={checkUserComplete} />
                      <ProfilePageEditController history={props.history} checkUserComplete={checkUserComplete}/>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderController history={props.history} />
                      <ProfilePageEditController history={props.history} checkUserComplete={checkUserComplete}/>
                    </React.Fragment>
                  )
                )}
              </div>
            );
        }} />
        <Route exact path="/news-details/:id" render={props => {
          return (
              <div>
                  {(token === null) ? (
                      <Redirect to="/login"/>
                  ) : (
                    isProfileCompleted === false ? (
                      <React.Fragment>
                        <Modal history={props.history} checkUserComplete={checkUserComplete} />
                          <NewsDetailController history={props.history} />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                          <HeaderController history={props.history} />
                          <NewsDetailController history={props.history} />
                      </React.Fragment>
                    )
                  )}
              </div>
          );
      }} />
        <Route exact path="/announcements-details/:id" render={props => {
          return (
              <React.Fragment>
                  <HeaderController history={props.history} />
                  <BullettinDetailController history={props.history} />
              </React.Fragment>
          )
      }} />
        <Route exact path="/surveys-details/:id" render={props => {
          return (
              <div>
                {(token === null) ? (
                  <Redirect to="/login"/>
                ) : (
                  <React.Fragment>
                    <HeaderController history={props.history} />
                    <SurveysDetailPageController history={props.history} />
                  </React.Fragment>
                )}
              </div>
            );
        }} />
         <Redirect to="/"/>
      </Switch>
    </React.Fragment>
  );

}

//each component that uses context.t (or this.context.t) has to use propsTypes to wrap "t"
App.contextTypes = {
  t: PropTypes.func
}

export default connect(null, mapDispatchToProps)(App);

/*{context.t('title')}*/
