import React from 'react';
import Measure from 'react-measure';

class ProgressBar extends React.Component {

    constructor(props,context){
        super(props,context);

        this.state = {
            dimensions: {
                width: -1,
                height: -1,
            },
            marginError : 15
        }
    }

    render(){
        let  { width } = this.state.dimensions;
        if ( this.props.value > 0)
            width = width * ( this.props.value / 100) - this.state.marginError;
        if ( this.props.value === 0 )
            width = width * ( this.props.value / 100);

        return ( 
            <Measure
                bounds
                onResize={contentRect => {
                this.setState({ dimensions: contentRect.bounds })
                }}
            >
                {({ measureRef  }) => (
                    <React.Fragment>
                        <div ref={measureRef } className="progress" style={{height:"10px"}}>
                            <div className="progress-bar" style={{width:this.props.value + '%' }}></div>
                        </div>
                        {this.props.showValue ? <div className="text-primary text-left" style={{marginLeft:width}}>{this.props.value}%</div> : '' }
                    </React.Fragment>
                )}
            </Measure>        
        )
    }
}

export default ProgressBar;