import React from "react";
import { connect } from "react-redux";
import Login from "../../components/login/Login";

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};
class LoginController extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {};
  }

  render() {
    return <Login history={this.props.history} changeToken={this.props.changeToken} checkUserComplete={this.props.checkUserComplete}/>;
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginController);
