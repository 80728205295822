import React, {Component} from 'react';
import { withRouter} from "react-router-dom";
import DOMPurify from 'dompurify';
import {Slider} from 'antd';
import 'antd/dist/antd.css';
import _ from "lodash";
import API from "../utils/API/api";
import './SurveysPage.scss';

class SurveysDetailPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.state = {
            title: null,
            content: null,
            background: null,
            questions: [],
            queryData: [],
            quizID: null,
            submit_label_btn: 'Submit Survey'
        }

        // OLD CODE ---------------------------------------------

        //this.componentDidMount = this.componentDidMount.bind(this);
        /* this.state = {
            text: '',
            isLoaded: false,
            surveys_content_lbspa: '',
            acf: {},
            post_title: null,  //ok
            surveys_excerpt_lbspa: '',
            surveys_survey_id_lbspa: {},
            surveys_content: null, //ok
            image_url: null, //ok
            surveys_featured_image_lbspa: null,
            url: null,
            metadata: null,
            questions: [], //ok
            quiz_questions: null, //ok
            queryList: [],
            counter: '',
            counterQ: [],
        } */
        //this.meta_value = '';
        //this.quizID = this.props.match.params.id;
    }

    async componentDidMount() {

        try {
            const uid = this.props.match.params.id;

            let survey_content = await API.get(`/wp/v2/surveys/${uid}`);
            const content = survey_content.data;

            /* acf */
            const acf = _.has(content, "acf") ? content.acf : {};
            console.log('acf', acf);

            /* ID */
            const surveyId = _.has(acf, "surveys_survey_id_lbspa.ID") ? acf.surveys_survey_id_lbspa.ID : null ;

            /* content */
            let surveys_content = _.has(acf, "surveys_content_lbspa") ? acf.surveys_content_lbspa : '';
            surveys_content = surveys_content.replace(/<\/?p[^>]*>/g, "");

            /* title */
            const post_title = _.has(acf, "surveys_survey_id_lbspa.post_title") ? acf.surveys_survey_id_lbspa.post_title : '';

            /* featured image  */
            const image_url = _.has(acf, "surveys_featured_image_lbspa.url") ? acf.surveys_featured_image_lbspa.url : '';
            const bgImage = { backgroundImage: 'url(' + image_url + ')' };

            let quizId = null;
            let questions = [];

            if (surveyId) {
                const quiz_content = await API.get('/custom-get-survey-resource/v1/', {post_id: surveyId});
                const metadata = quiz_content.data;
                quizId =  (metadata.length) ? metadata[0].meta_value : null;
                questions = await API.get('/quiz-survey-master/v1/questions/', {quizID: quizId});
                questions = questions.data;
            }

            this.setState({
                title: post_title,
                content: surveys_content,
                background: bgImage,
                questions: questions,
                quizID: quizId
            });
        } catch (e) {
            console.log('error', e);

        }
    }

    onCommentChange = (i, commentValue, quiz) => {

        let list = this.state.queryData;
        const indexOfQuestion = list.find(item => item.questionID === quiz.id)
        if (indexOfQuestion === undefined) {
            let newObj = {
                questionID: quiz.id,
                questionNumber: i++,
                sliderValue: 0,
                comment: commentValue.target.value
            }
            list = [...list, newObj];
        } else {
            indexOfQuestion.comment = commentValue.target.value
        }

        this.setState({
            queryData: list
        });

    }

    onSliderChange = (i, sliderValue, quiz) => {

        /*let counter_question = this.state.counterQ;
        counter_question.push(i + 'Q');*/

        let list = this.state.queryData;
        const indexOfQuestion = list.find(item => item.questionID === quiz.id)
        if (indexOfQuestion === undefined) {
            let newObj = {
                questionID: quiz.id,
                questionNumber: i++,
                sliderValue: sliderValue,
                comment: ''
            }
            list = [...list, newObj];
        } else {
            indexOfQuestion.sliderValue = sliderValue
        }

        this.setState({
            queryData: list
        });

    }

    update_questions = async () => {
        try {
            let qlist = "";
            let cquestions = "";

            this.state.questions.forEach(item => {
                const findData = this.state.queryData.find(obj => obj.questionID === item.id);
                if(findData === undefined) {
                    qlist += 'question' + item.id + '=1&mlwComment' + item.id + '=&';
                } else {
                    qlist += 'question' + item.id + '=' + findData.sliderValue + '&mlwComment' + item.id + '=' + findData.comment + '&';
                }
                cquestions += item.id + 'Q';
            });

            const form = new FormData();
            form.append('action', 'qmn_process_quiz');
            // form.append('quizData', 'question1=2&question2=1&qmn_question_list=1Q2Q&total_questions=2&qmn_quiz_id=1&complete_quiz=confirmation');
            form.append('quizData', qlist + 'qmn_question_list=' + cquestions + '&total_questions=' + this.state.questions.length + '&qmn_quiz_id=' + this.state.quizID + '&complete_quiz=confirmation');

            await API.post('/custom-submit-survey-resource/v1/', form);
            this.setState({
                submit_label_btn: 'Survey submitted'
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    goBack() {
        this.props.onBack();
    }

    render() {

        return (
            <div className="container-fluid main-container bg-lined bg-gray">
                <div className="box-shadowed no-max-width pt-10">
                    <div className="row bg-white">
                        <div className="col-12 blue-line">&nbsp;</div>
                    </div>
                    <div className="row bg-white">
                        {/*DESKTOP VERSION */}
                        <div className="col-sm-8 text-left text-primary pb-10 d-none d-xl-block">
                            <div onClick={() => this.goBack()} className="d-flex pt-10 cursor-pointer">
                                <span className="arrow-left active left align-self-center">&nbsp;</span>
                                <span className="ml-15 fs-22 align-self-center"><strong>Home</strong> </span>
                            </div>
                        </div>
                    </div>
                    <div className=" row bg-light-gray">
                        <div className="col-sm-12 col-md-6 border-right text-left">
                            <div className="row">
                                <div className="col-12 my-2">
                                    <div className="featured-media" style={this.state.background}></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-black my-2">
                                    <h1 className="text-primary text-left">
                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.title)}}/>
                                    </h1>
                                    <span>
                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.content)}}/>
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-12 text-black my-2">
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div>
                                        {
                                            this.state.questions.map((value, index) => {
                                                let marks = {};
                                                value.answers.forEach((elem, index) => {
                                                    marks[1+index] = "" + (1+index);
                                            })
                                            return (
                                                <div className="row" key={index.toString()}>
                                                    <div className="col my-5">

                                                        <span className="font-weight-bold text-white bg-secondary fs-48">{index + 1}.</span>
                                                        <span className="mx-2" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(value.name)}}/>
                                                        <div>

                                                            <Slider
                                                                onChange={sliderValue => this.onSliderChange(index, sliderValue, value)}
                                                                marks={marks}
                                                                min={1}
                                                                max={value.answers.length} step={1}
                                                                defaultValue={1}/>
                                                        </div>


                                                        {(parseInt(value.comments,10) === 0 || parseInt(value.comments,10) === 2)
                                                            ? (
                                                                <div>
                                                                    <span className="text-black mx-4 ">Comments or suggestions: </span>
                                                                    <form className="mx-5 ">
                                                                        <input

                                                                            type="text"
                                                                            onChange={commentValue => this.onCommentChange(index, commentValue, value)}
                                                                            placeholder="Write here"/>
                                                                    </form>
                                                                </div>) : (" ")}
                                                    </div>
                                                </div>

                                            )

                                        })}
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center my-5">
                                    <div>
                                        <strong
                                            onClick={this.update_questions}
                                            className="text-secondary cursor-pointer  fs-22 ">
                                            {this.state.submit_label_btn}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(SurveysDetailPage);
