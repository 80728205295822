import React , { Component }from 'react';


class GoToCourseMaterials extends Component{
    constructor(props,context){
        super(props,context);

        this.state = {}
    }
    render(){
        return(
            <div className="bg-secondary-blue box-shadowed  pt-30 pb-30 pl-20 pr-20 color-white text-left fs-20">
                <strong>Go to course material</strong>
                <div className="enter active right"/>
            </div>
        )
    }
    
}

export default GoToCourseMaterials;