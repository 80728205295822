import React from 'react'
import API from "../API/api";
import {Slider} from "antd";


class InputSearch extends React.Component {

    constructor(props,context){
        super(props,context);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            edit : false,
            value:''
        }
        this.text="";
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log('props', this.props.value);
        localStorage.setItem('search-query', this.props.value);
        this.setState({ edit:false });
        this.props.onSubmit();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ edit : false });
        }
    }

    handleClick(canEdit){
        this.setState({edit:!canEdit});
        if(canEdit)
            this.props.onReset();
        console.log(this.props.value);
    }
    //  componentDidMount=async() =>{
    //     document.addEventListener('mousedown', this.handleClickOutside);
    //
    //
    //     console.log(search_news);
    //
    //
    // }

    render(){

        console.log(this.props.value);

        const canEdit = this.state.edit || ( this.props.value ? this.props.value.length > 0 : false );

        return(
            <form ref={ ref => this.wrapperRef = ref }
                  onSubmit={this.handleSubmit}>

                {
                    canEdit  ? (
                        <input
                                value={this.props.value ? this.props.value : ''}
                                ref={ value => this.input = value}
                                placeholder={this.props.placeholder ? this.props.placeholder : ''}
                                type="text"
                                className="mr-15 mr-10-mobile"
                                autoFocus={this.props.value.length > 0}
                                onChange={e => this.props.onChange ? this.props.onChange(e.target.value) : ''}
                        />
                    ) : ''
                }
                <span onClick={ () => this.handleClick(canEdit)}
                      className={"cursor-pointer " + ( canEdit ? "clear" : "search" ) + " active right"}/>
            </form>
        )
    }
}

export default InputSearch;
