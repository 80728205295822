import React from 'react';
import { render } from "react-dom";
import App from './controllers/App';
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import store from "./store";
import I18n from "redux-i18n";
import {translations} from "./config/translations";

import { Router  } from "react-router-dom";
import history from './utils/history'


import './scss/custom.scss';

//default = it otherwise en
let browserLang = navigator.language || navigator.userLanguage;
// console.log('lang', browserLang);
if ( browserLang !== 'it' )
  browserLang = 'en';

render(
    <Provider store={store}>
      <Router history={history}>
        <I18n translations={translations} initialLang={browserLang}>
          <App />
        </I18n>
      </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
