import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DOMPurify from 'dompurify';
import API from '../../utils/API/api';
import './NewsDetail.scss';
import ReactPlayer from "react-player";
import _ from 'lodash';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon
} from 'react-share';

class NewsDetail extends Component {
    constructor(props, context) {
        super(props, context);
        this.goBack = this.goBack.bind(this);
        this.getCommentContent = this.getCommentContent.bind(this);
        this.postComment = this.postComment.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.postLike = this.postLike.bind(this);

        this.state = {
            isLoaded: false,
            title: '',
            date: null,
            media: '',
            flexibleContent: [],
            categories: [],
            comments: [],
            me: {},
            commentContent: '',
            shareUrl: '',
            showModal: false
        };

        this.newsId = this.props.match.params.id;
    }

    async componentDidMount() {
        try {

            const shareUrl = window.location.href;

            let news = await API.get(`/wp/v2/news/${this.newsId}`);
            news = news.data;

            API.post(`/custom-read-news-resource/v1/?post_id=${this.newsId}`);

            let commentList = await this.getComments(this.newsId);
            commentList = commentList.data;

            /* resolve an array of promises to add on each comment object the required user (that made this comment) attributes */
            await Promise.all(
              commentList.map(async comment => {
                comment.date = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(comment.date));
                comment.userFirstName = comment.user_first;
                comment.userLastName = comment.user_last;
                comment.userImage = comment.user_pic_url;
                return comment;
              })
            );

            let sharable = false;
            if (_.has(news, 'acf.news_public_flag_lbspa')) {
                sharable = news.acf.news_public_flag_lbspa;
            }

            let media = '';
            if (_.has(news, 'acf.news_featured_image_lbspa.url')) {
                media = news.acf.news_featured_image_lbspa.url;
            }

            let me = await API.get('/wp/v2/users/me');
            me = me.data;

            let flexibleContent = [];
            if (_.has(news, 'acf.news_content_lbspa')) {
                flexibleContent = news.acf.news_content_lbspa.map((value, i) => {
                    return (
                        <div className="flexible-content" key={i.toString()}>
                            <div key={i.toString()} className={value.acf_fc_layout} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.news_content_text_lbspa) }} />
                        </div>
                    );
                })
            }

            if (_.has(news, 'acf.news_url_video_lbspa')) {
                flexibleContent =
                (
                    <div className='player-wrapper'>
                        <ReactPlayer
                            className='react-player'
                            url={news.acf.news_url_video_lbspa}
                            playing
                            controls
                            width='100%'
                            height= '100%'
                            />
                    </div>
                );

            }

            let views = news.reads_count;
            let likes = news.likes_count;

            this.setState({
                shareUrl,
                sharable: sharable,
                isLoaded: true,
                title: news.title.rendered,
                media: media,
                date: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(news.date)),
                flexibleContent: flexibleContent,
                content: news.content.rendered,
                categories: news.categories_label,
                comments: commentList,
                me: me,
                commentContent: '',
                views: views,
                likes: likes
            });
        } catch (e) {
            console.log('error', e);
        }
    }

    handleShowModal() {
        this.setState({
            showModal: true
        })
    }
    
    handleCloseModal() {
        this.setState({
            showModal: false
        })
    }

    goBack() {
        this.props.goHome();
    }

    getCommentContent(event) {
        this.setState({commentContent: event.target.value});
    }

    async postComment(event) {
        event.preventDefault();
        try {
            let comments = await API.post('/wp/v2/comments', {
                author: this.state.me.id,
                author_name: this.state.me.name,
                author_email: this.state.me.user_email,
                content: this.state.commentContent,
                post: this.newsId
            });

            if (comments.status === 201) {

                let updatedComments = await this.getComments(this.newsId);
                updatedComments = updatedComments.data;
                /* resolve an array of promises to add on each comment object the required user (that made this comment) attributes */
                await Promise.all(
                    updatedComments.map(async comment => {
                        comment.date = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(comment.date));
                        comment.userFirstName = comment.user_first;
                        comment.userLastName = comment.user_last;
                        comment.userImage = comment.user_pic_url;
                        return comment;
                    })
                );

                this.setState({
                    comments: updatedComments,
                    commentContent: ''
                });
            }
        } catch (e) {
            console.log('error', e);
        }
    }

    getComments = (id) => API.get('wp/v2/comments/', {post: id, order: 'asc', per_page: 100});

    //postLike = () => API.get(`/custom-like-news-resource/v1?post_id=${this.newsId}`);

    async postLike() {
        let result = await API.get(`/custom-like-news-resource/v1?post_id=${this.newsId}`);
        if (result.status === 200) {
            let tmp_me = this.state.me;
            console.log('tmp_me: ', tmp_me);
            if (tmp_me !== undefined) {
                if (_.has(tmp_me, 'user_likes') && tmp_me.user_likes !== null) {
                    tmp_me.user_likes.push(this.newsId);
                } else {
                    tmp_me.user_likes = [];
                    tmp_me.user_likes.push(this.newsId);
                }
                this.setState({
                    me: tmp_me,
                    likes: (parseInt(this.state.likes,10) + 1)
                });
            }
        }
    }


    postDislike = () => API.get(`/custom-dislike-news-resource/v1?post_id=${this.newsId}`);

    /* get user object from comment author (user id) */
    getUser = (id) => API.get(`/wp/v2/users/${id}`);

    render() {
        const bgImage = { backgroundImage: 'url(' + this.state.media + ')' };

        let userImage = 'https://picsum.photos/seed/picsum/160';
        if (_.has(this.state, 'me.acf.user_image_lbspa.url') && (!_.isEmpty(this.state.me.acf.user_image_lbspa.url))) {
            userImage = this.state.me.acf.user_image_lbspa.url;
        }

        const newsComments = this.state.comments.map((comment, i) => {
            return (
                <div key={i.toString()} className="row mb-3 bg-white pl-10 pr-10">
                    <div className="col-9 bg-light-gray pl-0 pr-0">
                        <p className="color-gray pt-2">
                            <span className="d-block w-50 float-left text-left"> {comment.userFirstName} {comment.userLastName}</span>
                            <span className="d-block w-50 float-right text-right"> {comment.date} </span>
                        </p>
                        <p className="mt-3 pt-2 text-black" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content.rendered) }}/>
                    </div>
                    <div className="col-3">
                        <img alt="user" src={(comment.userImage ==='') ? 'https://picsum.photos/seed/picsum/160' :  comment.userImage} className="img-fluid" />
                    </div>
                </div>
            );
        })

        return (
            <div>
                <div className="container-fluid main-container bg-lined bg-gray">
                    <div className="row bg-white">
                        <div className="col-12 blue-line"></div>
                    </div>

                    <div className="row bg-white news-navbar">
                        <div className="col-sm-12 col-md-7 d-none d-sm-flex align-items-center h-100 text-left text-primary">
                            <div onClick={() => this.goBack()} className="d-flex cursor-pointer">
                                <span className="arrow-left active left align-self-center"></span>
                                <span className="ml-15 fs-22 align-self-center font-weight-bold">Home</span>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-5">
                            <div className="row icon-list pb-10">
                                {this.state.sharable === true ? (
                                <div className="col-2 icon-item" onClick={this.handleShowModal}>
                                    <span className="share"></span>
                                </div>
                                ) : (
                                <div className="col-2 icon-item"></div>
                                )}
                                <div className="col-2 icon-item">
                                    <span className="eye"></span>
                                    <span className="text">{this.state.views}</span>
                                </div>
                                <div className="col-2 icon-item">
                                    <span className="comments"></span>
                                    <span className="text">{this.state.comments.length}</span>
                                </div>
                                <div className="col-2 icon-item">
                                    <span className="likes"></span>
                                    <span className="text">{this.state.likes}</span>
                                </div>
                                {_.indexOf(this.state.me.user_likes, this.newsId) > -1 ? (
                                <div className="col-4 icon-item cursor-pointer border-left">
                                    <span className="likes text-primary"></span>
                                    <span className="text font-weight-bold text-primary">Liked!</span>
                                </div>
                                ) : (
                                <div className="col-4 icon-item cursor-pointer border-left" onClick={this.postLike}>
                                    <span className="likes text-primary"></span>
                                    <span className="text font-weight-bold text-primary">Like</span>
                                </div>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="row bg-white">
                        <div className="col-sm-12 col-md-7 border-right text-left">
                            <div className="row">
                                <div style={bgImage} className="featured-media mb-3"></div>
                                <div className="col-sm-12 col-md-7">
                                    <h1 className="text-primary text-left"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.title) }}
                                    />
                                </div>

                                <div className="col-sm-12 col-md-5 text-right text-black">
                                    <span> {this.state.date} </span> |
                                    <span className="text-primary font-weight-bold"> Category </span> |
                                    {this.state.categories.map((category, i) => {
                                        return (
                                            <span key={i.toString()} className="text-black font-weight-bold">
                                                {category}
                                            </span>
                                        );
                                    })}
                                </div>

                                <div className="col-sm-12 text-black my-2">
                                    {this.state.flexibleContent}
                                </div>

                                <div className="col-sm-12 text-black my-2">
                                    {this.state.content}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-5 position-relative mt-4">
                            {newsComments}
                            <div className="row comment-input">
                                <div className="col-xs-2">
                                    <img alt="profile" src={userImage} className="img-comments img-fluid"/>
                                </div>
                                <div className="col-xs-8">
                                    <input type="text" className="comment-text" placeholder="Type your comment here"
                                        value={this.state.commentContent} onChange={this.getCommentContent} />
                                </div>
                                <div className="col-xs-2">
                                    <button
                                        onClick={this.postComment}
                                        className="btn btn-secondary btn-block rounded-0"
                                        type="button">
                                        {this.context.t('send')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal 
                    show={this.state.showModal} 
                    onHide={this.handleCloseModal}
                    centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col>
                                    <FacebookShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button">
                                        <FacebookIcon
                                            size={40}
                                            round />
                                    </FacebookShareButton>
                                </Col>
                                <Col>
                                    <LinkedinShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button shareIconModal">
                                        <LinkedinIcon
                                            size={40}
                                            round />
                                    </LinkedinShareButton>
                                </Col>
                                <Col>
                                    <TwitterShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button shareIconModal">
                                        <TwitterIcon
                                            size={40}
                                            round />
                                    </TwitterShareButton>
                                </Col>
                                <Col>
                                    <TelegramShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button shareIconModal">
                                        <TelegramIcon
                                            size={40}
                                            round />
                                    </TelegramShareButton>
                                </Col>
                                <Col>
                                    <WhatsappShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button shareIconModal">
                                        <WhatsappIcon
                                            size={40}
                                            round />
                                    </WhatsappShareButton>
                                </Col>
                                <Col>
                                    <EmailShareButton
                                        url={this.state.shareUrl}
                                        quote={this.state.title}
                                        className="Demo__some-network__share-button shareIconModal">
                                        <EmailIcon
                                            size={40}
                                            round />
                                    </EmailShareButton>
                                </Col>

                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(NewsDetail);

NewsDetail.contextTypes = {
  t: PropTypes.func
}
