import Bullettin from '../../../components/home/bullettin/Bullettin';
import News from '../../../components/home/news/News';
import Profile from '../../../components/home/profile/Profile';
import Surveys from '../../../components/home/surveys/Surveys';

const v5 = {
    header : {},
    body : [
        { name : 'Profile' , component : Profile},
        { name : 'News' , component : News},
        { name : 'Bullettin' , component : Bullettin},
        { name : null , component : null}, //needed for become Surveys at position 4 instead of 3
        { name : 'Surveys' , component : Surveys}
    ]
}

export default v5;