import React from 'react';
import { withRouter } from 'react-router';
import API from '../utils/API/api';
import './Login.scss';

class Login extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.login = this.login.bind(this);

        this.state = {
            isAuthenticated: false,
            logging: false
        };
    }

    login() {
        this.setState({
            logging: true
        });

        const domain = "https://test-apilbs.luiss.it";
        let externalWindow = window.open(domain + '/wp-login.php?action=wp-saml-auth&redirect_to=https%3A%2F%2Ftest-apilbs.luiss.it%2Fwp-admin%2F ', '_blank', 'width=600,height=400,left=200,top=200');

        //const domain = "https://apilbs.luiss.it";
        //let externalWindow = window.open(domain + '/wp-login.php?action=wp-saml-auth&redirect_to=https%3A%2F%2Fapilbs.luiss.it%2Fwp-admin%2F ', '_blank', 'width=600,height=400,left=200,top=200');

        let interval = setInterval(() => {
            externalWindow.postMessage({
                codeRequest: 'checkToken'
            }, domain); //send the message and target URI
            if (externalWindow.closed && !this.state.isAuthenticated) {
                clearInterval(interval);
                this.setState({
                    logging: false
                });
            }
        },1000);

        window.addEventListener("message", (event) => {
            if (event.origin !== domain)
                return;
            clearInterval(interval);
            window.localStorage.setItem('auth-token', event.data.token);
            this.props.changeToken(event.data.token);
            API.setToken(event.data.token);
            this.setState({
                isAuthenticated: true
            });
            externalWindow.close();
            this.props.checkUserComplete().then(() => {
                this.props.history.push('/');
            });
        }, false);

    }

    render() {

        return (
            <div className="login-container d-sm-flex align-content-stretch flex-wrap h-100 align-items-center">
                <div className="col-sm-12 col-md-6 order-sm-1 h-md-100 bg-primary p-0">
                    <div className="d-flex h-100 align-items-center justify-content-center p-5">
                        <div className="text-white text-center">
                            {this.state.logging === false ?
                                <button type="button" className="btn btn-secondary btn-lg btn-login" onClick={this.login}>LOGIN</button>
                                :
                                <h1 className="logging">Logging in <span>.</span><span>.</span><span>.</span></h1>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 order-sm-2 h-md-100 bg-light-gray p-0">
                    <div className="d-flex h-100 align-items-center justify-content-center text-primary text-center p-5">
                        <div className="text-primary logo text-center">
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Login);
