

const middleware = ({ dispatch }) => {
  return next => {
    return action => {
      // do your stuff
      return next(action);
    };
  };
}

export {
    middleware
}