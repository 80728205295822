import React, { Component } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import InfiniteScroll from 'react-infinite-scroller';
import _ from "lodash";
import API from "../utils/API/api";

class SurveysPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.goHome=this.goHome.bind(this);

    this.state = {
      isLoaded: true,
      surveys: [],
      page: 1,
      hasMore: true,      
      error: {},
    };
    this.surveyItems = [];
  }

  loadData = async () => {
    try {
      const response = await API.get("/wp/v2/surveys", { page: this.state.page, order: 'desc' });

      const total = parseInt(response.headers["x-wp-totalpages"], 10);

      console.log('api response: ',response);
      /* check if there's a next page */
      if (total - this.state.page) {
        console.log('we have other page')
        this.setState({
          isLoading: false,
          hasMore: true,
          surveys: response.data,
          page: this.state.page + 1
        });
      } else {
        console.log('page are ending');
        this.setState({
          surveys: response.data,
          hasMore: false
        });
      }
    } catch (error) {
      this.setState({
        error: error,
        hasMore: false
      });
    }
  };

  goHome(){
    this.props.onBack();
  }

  render() {
    console.log('survays (state): ', this.state.surveys);
    this.state.surveys.map(value => {
      // id
      const id = _.has(value, "id") ? value.id : null;
      // title
      const title = _.has(value, "acf.surveys_survey_id_lbspa.post_title") ? value.acf.surveys_survey_id_lbspa.post_title : "";
      // excerpt
      const excerpt = _.has(value, "acf.surveys_excerpt_lbspa") ? value.acf.surveys_excerpt_lbspa : "";
      // featured image
      const image_url = _.has(value, "acf.surveys_featured_image_lbspa.url") ? value.acf.surveys_featured_image_lbspa.url : "";
      const background = { backgroundImage: "url(" + image_url + ")" };

      this.surveyItems.push({
        id: id,
        title: title,
        excerpt: excerpt,
        background: background
      });
    });

    const loader = (
      <div className="spinner-box" key={'spinner-survey'}>
        <div className="solar-system">
          <div className="earth-orbit orbit">
            <div className="planet earth"></div>
            <div className="venus-orbit orbit">
              <div className="planet venus"></div>
              <div className="mercury-orbit orbit">
                <div className="planet mercury"></div>
                <div className="sun"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const surveyList = this.surveyItems.map((value, i) => {
      return (
        <div className="col-sm-12 col-md-6 bg-gray p-4 border-right border-bottom" key={i.toString()}>
          <React.Fragment>
          <div className="row survey-item">
            <div className="col-12 col-sm-5 col-md-4 col-lg-3">
              <div className="featured-media" style={value.background}></div>
            </div>
            <div className="col-12 col-sm-7 col-md-8 col-lg-9">
              <h3 className="font-weight-bold text-primary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.title) }} />
              <p className="py-2 text-black" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.excerpt) }} />
              <Link className="text-secondary cursor-pointer" to={`/surveys-details/${value.id}`} > START SURVEY </Link>
            </div>
          </div>
          </React.Fragment>
        </div>
      );      
    });

    return (
      <div id="survey-container"  className="container-fluid main-container bg-lined bg-gray">
        <div className="container box-shadowed no-max-width"></div>
        <div className="row bg-white">
          <div className="col-12 blue-line"></div>
        </div>
        
        <div className="row bg-white">
          <div className="col-12 text-left text-primary py-3">
            <div className="col-2 my-auto">
              <div className={"d-flex "}>
                <span
                  onClick={this.goHome}
                  className="cursor-pointer arrow-home hidden-mobile arrow-left left small active"
                >
                  {" "}
                  &nbsp;
                </span>
                <span
                  onClick={this.goHome}
                  className=" cursor-pointer ml-15 fs-22 align-self-center hidden-mobile"
                >
                  <strong>Home</strong>{" "}
                </span>
              </div>
              <h1 className="page-title text-primary">Surveys{" "}</h1>
            </div>
          </div>
        </div>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadData}
          hasMore={this.state.hasMore}
          loader={loader}
        >
        </InfiniteScroll>
        <div className="justify-content-center align-items-center"> {surveyList} </div>
      </div>
    )
  }
}

export default SurveysPage;