import News from '../../../components/home/news/News';
import Profile from '../../../components/home/profile/Profile';
import Surveys from '../../../components/home/surveys/Surveys';
import GoToCourseMaterials from '../../../components/home/goToCourseMaterial/GoToCourseMaterial';

const v2 = {
    header : {},
    body : [
        { name : 'Profile' , component : Profile},
        { name : 'News' , component : News},
        { name : 'GoToCourseMaterials' , component : GoToCourseMaterials},
        { name : 'Surveys' , component : Surveys}
    ]
}

export default v2;