import React, { Component } from 'react';
import DOMPurify from "dompurify";
import {Link} from "react-router-dom";
import _ from "lodash";
import ProgressBar from '../../utils/progressBar/ProgressBar';
import API from "../../utils/API/api";
import './profile.scss';

class Profile extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoaded: false,            
            acf: {},
            points: {},
            untilNextLevel: {},
            untilNextLevelProgress: 0,
            user_name_lbspa: '',
            user_surname_lbspa: '',
            user_title_lbspa: '',
            user_nationality_lbspa: '',
            user_country_lbspa: '',
            user_personal_email_lbspa: '',
            user_ssn_lbspa: '',
            user_region_lbspa: '',
            user_city_lbspa : '',
            user_mobile_number_lbspa: '',
            user_office_number_lbspa: '',
            user_linkedin_lbspa: '',
            user_skype_lbspa: '',
            user_educational_degree_lbspa: '',
            user_learning_activities_lbspa: '',
            user_not_employed_lbspa: '',
            user_gender_lbspa: '',
            user_toi_list: [],
            user_birthdate_lbspa:'',
            user_birthdatae_lbspa:'',
            user_image_lbspa:''
        }
    }
    
    async componentDidMount() {
        try {
          let userMe = await API.get("/wp/v2/users/me");
          userMe = userMe.data;
    
          let userTopics = (_.has(userMe, "acf.user_toi_list") && !_.isEmpty(userMe.acf.user_toi_list))
            ? userMe.acf.user_toi_list
            : [];
          let acf = {};
          acf = userMe.acf;
    
          let userImage = (_.has(acf, "user_image_lbspa.url") && !_.isEmpty(userMe.acf.user_image_lbspa.url))
            ? acf.user_image_lbspa.url
            : "https://picsum.photos/seed/picsum/160";
          
          /* count how many acf fields are being filled, out of 19, to calculate the progress level */
          let counter = Object.keys(acf);
          // console.log('start:',JSON.stringify(counter));
          let realCounter = counter.length;
          if (_.includes(counter, 'user_not_employed_lbspa')) {
            realCounter--;
            _.remove(counter, key => key === 'user_not_employed_lbspa');
          }
          if (_.includes(counter, 'user_job_title_lbspa')) {
            realCounter--;
            _.remove(counter, key => key === 'user_job_title_lbspa');
          }
          if (_.includes(counter, 'user_company_lbspa')) {
            realCounter--;
            _.remove(counter, key => key === 'user_company_lbspa');
          }
          counter.forEach((key)  => {
            if (acf[key] === '' || acf[key] === null || acf[key] === undefined)
              realCounter--;
          });
          const progress = parseInt(((100 / 19) * realCounter),10);

          /* points */
          const points = (_.has(userMe, "points") && !_.isEmpty(userMe.points)) ? userMe.points : { read: 0, like: 0, comment: 0 };
          /* points */
          const rankRequirements = (_.has(userMe, "rank_requirements") && !_.isEmpty(userMe.rank_requirements)) ? userMe.rank_requirements : { read: 0, like: 0, comment: 0 };
          
          const untilNextLevel = {
            read: ((rankRequirements.read - points.read) >= 0) ? (rankRequirements.read - points.read) : 0,
            like: ((rankRequirements.like - points.like) >= 0) ? (rankRequirements.like - points.like) : 0,
            comment: ((rankRequirements.comment - points.comment) >= 0) ? (rankRequirements.comment - points.comment) : 0,
          }

          let totalReqNext = 0;
          totalReqNext += userMe.rank_requirements.read;
          totalReqNext += userMe.rank_requirements.comment;
          totalReqNext += userMe.rank_requirements.like;

          let totalUserNext = 0;
          totalUserNext += untilNextLevel.read;
          totalUserNext += untilNextLevel.comment;
          totalUserNext += untilNextLevel.like;

          let untilNextLevelProgress = Math.round(100 - ((100 / totalReqNext) * totalUserNext));

          let rankLabel = userMe.rank;
          let nextRankLabel = userMe.next_rank;
              
          this.setState({
            ...this.state,
            ...{
              isLoaded: true,
              progress: progress,
              points: points,              
              untilNextLevel: untilNextLevel,
              untilNextLevelProgress: untilNextLevelProgress,
              rankLabel: rankLabel,
              nextRankLabel: nextRankLabel,
              user_email: userMe.user_email,
              user_toi_list: userTopics,
              user_name_lbspa: acf.user_name_lbspa,
              user_surname_lbspa: acf.user_surname_lbspa,
              user_title_lbspa: acf.user_title_lbspa,
              user_nationality_lbspa: acf.user_nationality_lbspa,
              user_country_lbspa: acf.user_country_lbspa,
              user_ssn_lbspa: acf.user_ssn_lbspa,
              user_region_lbspa: acf.user_region_lbspa,
              user_city_lbspa: acf.user_city_lbspa,
              user_mobile_number_lbspa: acf.user_mobile_number_lbspa,
              user_office_number_lbspa: acf.user_office_number_lbspa,
              user_linkedin_lbspa: acf.user_linkedin_lbspa,
              user_skype_lbspa: acf.user_skype_lbspa,
              user_educational_degree_lbspa: acf.user_educational_degree_lbspa,
              user_learning_activities_lbspa: acf.user_learning_activities_lbspa,
              user_not_employed_lbspa: acf.user_not_employed_lbspa,
              user_gender_lbspa: acf.user_gender_lbspa,
              user_birthdate_lbspa: acf.user_birthdate_lbspa,
              user_image_lbspa: userImage,
              user_company_lbspa: acf.user_company_lbspa,
              user_job_title_lbspa: acf.user_job_title_lbspa
            }
          });
    
        } catch (e) {
          console.log("error", e);
        }
      }


    render() {

        return (
          <div className="container  box-shadowed no-max-width-mobile">
            <div className="row">
              <div className="col-12 blue-line"></div>
            </div>
            <div className="row bg-white ">
              <div className="col-8 text-left text-primary fs-22 my-auto pl-20 d-none d-xl-block">
                <div className="row">
                  <div className="col-12 text-primary">
                    <h4 className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( this.state.user_name_lbspa + ' ' + this.state.user_surname_lbspa) }} />
                  </div>                  
                </div>
                <div className="row">
                  <div className="col-12 text-primary cursor-pointer fs-16 font-weight-bold">
                      <Link to="/profile">GO TO PROFILE</Link>
                  </div>
                </div>
              </div>
              <div className="col-4 align-right pr-0 d-none d-xl-block">
                <img
                  src={this.state.user_image_lbspa}
                  alt=""
                  className="prof-performance float-right"
                />
              </div>
            </div>

            <div
              onClick={() => this.props.onGoTo(1)}
              className="row"
            >
              <div className="col-10 pt-30 pt-20 pb-3 bg-gray cursor-pointer text-left fs-16 text-primary pl-20 my-auto d-none d-xl-block">
                <strong>COMPLETE YOUR PROFILE</strong>
                <br />
              </div>
              <div className="col-2  pt-30 pt-20 pb-3 cursor-pointer bg-gray pr-20 text-right pr-20 my-auto d-none d-xl-block">
                <span className="arrow-right active right small"></span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 bg-gray pl-20 pr-20 pb-20 d-none d-xl-block">
                <ProgressBar value={this.state.progress} />
                <h5 className="mt-2 text-primary">{this.state.progress}%</h5>
              </div>
            </div>

            <div className="row bg-gray text-primary pt-25 pb-30 pt-10-mobile pb-20-mobile bg-white-mobile">
              <div className="col-10 text-left pl-20 fs-26 fs-23-mobile">
                My Performance
              </div>
              <div className="col-2 text-center my-auto pr-20">
                <div
                  onClick={() => this.props.onGoTo(2)}
                  className="text-secondary text-right fs-15 cursor-pointer"
                >
                  <span className="open active right "></span>
                </div>
              </div>
            </div>

            <div id="main-profile-level" className="row justify-content-center align-items-center bg-gray bg-white-mobile">
              <div id="level-header" className="col-12 col-md-11 offset-md-1">
                <h4 className="h4 text-black text-left">My Level</h4>
              </div>
              <div className="col-3 text-center">
                <span className="prize empty"></span>
                <p className="text-center d-none d-xl-block">{this.state.rankLabel} Level </p>
              </div>
              <div className="col-6">
                <div className="w-100 align-middle text-center text-primary">
                  <ProgressBar value={this.state.untilNextLevelProgress} />
                  <p className="mt-2">{this.state.untilNextLevelProgress} %</p>
                </div>
              </div>
              <div className="col-3 text-center">
                <span className="prize full"></span>
                <p className="text-center d-none d-xl-block">{this.state.nextRankLabel} Level</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 fs-12 text-left pl-20 pt-10 bg-gray bg-white-mobile fs-16">
                To reach the next level:
              </div>
            </div>

            <div className="row border-bottom no-border-bottom-mobile pt-20 pt-15-mobile pb-20 bg-gray bg-white-mobile">
              <div className="col-4 text-center pl-20">
                <div className="d-flex justify-content-center">
                  <span className="mr-5 fs-22"> {this.state.untilNextLevel.read} </span>
                  <span className="eye"></span>
                </div>
              </div>
              <div className="col-4 text-center">
                <div className="d-flex justify-content-center">
                  <span className="mr-5 fs-22"> {this.state.untilNextLevel.like} </span>
                  <span className="likes"></span>
                </div>
              </div>
              <div className="col-4 text-center pr-20">
                <div className="d-flex justify-content-center">
                  <span className="mr-5 fs-22"> {this.state.untilNextLevel.comment} </span>
                  <span className="comments"></span>
                </div>
              </div>
            </div>

          </div>
        );
    }

}

export default Profile;
