import React from 'react';
import Modal from 'react-modal';
import {Form, FormControl, InputGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";

import _ from "lodash";
import API from "../utils/API/api";

import "react-datepicker/dist/react-datepicker.css";
import "../profilePage/ProfilePage.scss";


const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        'max-height'          : '100vh',
        'overflow-y'          : 'auto'
    }
};

class ModalComponent extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                id: null,
                name: null,
                surname: null,
                email: null
                /*
                nationality: null,
                country: null,
                region: null,
                city: null,
                email: null,
                linkedin: null,
                skype: null,
                gender: null,
                birthdate: null
                */
            },
            userResultData: null,
            modalIsOpen: true
        }

        this.updateUser = this.updateUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount() { 
        try {
            let userMe = await API.get("/wp/v2/users/me");
            userMe = userMe.data;
            this.setState({
                user: {
                    id: userMe.id,
                    name: userMe.acf.user_name_lbspa,
                    surname: userMe.acf.user_surname_lbspa,
                    email: userMe.acf.user_personal_email_lbspa
                    /*
                    nationality: userMe.acf.user_nationality_lbspa,
                    country: userMe.acf.user_country_lbspa,
                    region: userMe.acf.user_region_lbspa,
                    city: userMe.acf.user_city_lbspa,
                    email: userMe.acf.user_personal_email_lbspa,
                    linkedin: userMe.acf.user_linkedin_lbspa,
                    skype: userMe.acf.user_skype_lbspa,
                    gender: userMe.acf.user_gender_lbspa,
                    birthdate: userMe.acf.user_birthdate_lbspa !== undefined ? this.parseDate(userMe.acf.user_birthdate_lbspa) : null
                    */
                },
                userResultData: userMe
            });
        } catch(error) {
            console.log("Error", error);
        }
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
        document.body.style.overflow = 'hidden';
    }

    closeModal() {
        this.setState({modalIsOpen: true});
        document.body.removeAttribute('style');
    }

    parseDate = input => {
        const parts = input.match(/(\d+)/g);
        if (parts != null) {
            // note parts[1]-1
            return new Date(parts[2], parts[1] - 1, parts[0]);
        }
        return null;
    };

    handleDateChange = date => {
        let tempUser = this.state.user;
        tempUser.birthdate = date;
        this.setState({
            user: tempUser
        });
    };

    handleChange(event, tag) {
        let tempUser = this.state.user;
        const tmp = event.target.value.match(/[^\s]/i);
        if (tmp !== null) {
            tempUser[tag] = event.target.value;
            this.setState({
                user: tempUser
            });
        } else {
            tempUser[tag] = '';
            this.setState({
                user: tempUser
            });
        }
    }

    async updateUser (){
        try {
            console.log('this.state: ', this.state);
            let userOldData = this.state.userResultData;
            userOldData.acf.user_name_lbspa = this.state.user.name
            userOldData.acf.user_surname_lbspa = this.state.user.surname
            userOldData.acf.user_personal_email_lbspa = this.state.user.email
            /*
            userOldData.acf.user_nationality_lbspa = this.state.user.nationality
            userOldData.acf.user_country_lbspa = this.state.user.country
            userOldData.acf.user_region_lbspa = this.state.user.region
            userOldData.acf.user_city_lbspa = this.state.user.city
            userOldData.acf.user_personal_email_lbspa = this.state.user.email
            userOldData.acf.user_linkedin_lbspa = this.state.user.linkedin
            userOldData.acf.user_skype_lbspa = this.state.user.skype
            userOldData.acf.user_gender_lbspa = this.state.user.gender
            userOldData.acf.user_birthdate_lbspa = this.state.user.birthdate
            */

            await API.post("/acf/v3/users/" + this.state.user.id, { acf: userOldData.acf });

            const userComplete = await this.props.checkUserComplete();
            console.log("Complete:", userComplete)

            if(userComplete) {
                this.setState({
                    modalIsOpen: false
                });
                document.body.removeAttribute('style');
                this.props.history.push("/");
            }

        } catch (err) {
            console.log("Error:", err);
        }
    };

    render() {

        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Modal"
                >

                    <span ref={subtitle => this.subtitle = subtitle} className="text-primary">Hi,</span><br/>
                    <span ref={subtitle => this.subtitle = subtitle} className="text-muted">
                        Before starting using the platform, please complete your profile. It will increase your performance level! </span>

                    <div className="row profile-row">
                        <div className="col-md-3">
                            <label htmlFor="user_name_lbspa">Name</label>
                            <InputGroup>
                                <FormControl
                                    id="user_name_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.name}
                                    onChange={event => this.handleChange(event, "name")}
                                    onSelect={event => this.handleChange(event, "name")}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="user_surname_lbspa">Surname</label>
                            <InputGroup>
                                <FormControl
                                    id="user_surname_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.surname}
                                    onChange={event => this.handleChange(event, "surname")}
                                    onSelect={event => this.handleChange(event, "surname")}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-3 col-md-3">
                            <label htmlFor="user_email_lbspa">Personal Email</label>
                            <InputGroup>
                                <FormControl
                                    id="user_email_lbspa"
                                    aria-describedby="user-input"
                                    type="email"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.email}
                                    onChange={event => this.handleChange(event, "email")}
                                    onSelect={event => this.handleChange(event, "email")}
                                />
                            </InputGroup>
                        </div>
                        {/*
                        <div className="col-md-3">
                            <label htmlFor="user_nationality_lbspa">Nationality</label>
                            <InputGroup>
                                <FormControl
                                    id="user_nationality_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.nationality}
                                    onChange={event => this.handleChange(event, "nationality")}
                                    onSelect={event => this.handleChange(event, "nationality")}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="usern_region_lbspa">Region</label>
                            <InputGroup>
                                <FormControl
                                    id="user_region_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.region}
                                    onChange={event => this.handleChange(event, "region")}
                                    onSelect={event => this.handleChange(event, "region")}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="user_city_lbspa">City</label>
                            <InputGroup>
                                <FormControl
                                    id="user_city_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.city}
                                    onChange={event => this.handleChange(event, "city")}
                                    onSelect={event => this.handleChange(event, "city")}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-3">
                            <Form.Group controlId="user_gender_lbspa">
                                <Form.Label className="text-black">Gender</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={
                                        this.state.user.gender
                                            ? this.state.user.gender
                                            : "Male"
                                    }
                                    onChange={event => this.handleChange(event, "gender")}
                                >
                                    <option>Male</option>
                                    <option>Female</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="user_country_lbspa">
                                <Form.Label className="text-black">Country</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={
                                        this.state.user.country
                                            ? this.state.user.country
                                            : "Italy"
                                    }
                                    onChange={event => this.handleChange(event, "country")}
                                >
                                    <option>British Antilles</option>
                                    <option>Afghanistan</option>
                                    <option>Albania</option>
                                    <option>Algeria</option>
                                    <option>American Samoa</option>
                                    <option>Andorra</option>
                                    <option>Angola</option>
                                    <option>Anguilla</option>
                                    <option>Antarctic Neozeland Dependencies</option>
                                    <option>Antigua And Barbuda</option>
                                    <option>Argentina</option>
                                    <option>Armenia</option>
                                    <option>Australia</option>
                                    <option>Australian Dependencies</option>
                                    <option>Austria</option>
                                    <option>Azerbaijan</option>
                                    <option>Bahamas</option>
                                    <option>Bahrain</option>
                                    <option>Bangladesh</option>
                                    <option>Barbados</option>
                                    <option>Belarus</option>
                                    <option>Belgium</option>
                                    <option>Belize</option>
                                    <option>Benin</option>
                                    <option>Bermuda</option>
                                    <option>Bhutan</option>
                                    <option>Bolivia</option>
                                    <option>Bophuthatswana</option>
                                    <option>Bosnia And Herzegovina</option>
                                    <option>Botswana</option>
                                    <option>Brazil</option>
                                    <option>British Dependencies</option>
                                    <option>British Virgin Islands</option>
                                    <option>Brunei Darussalam</option>
                                    <option>Bulgaria</option>
                                    <option>Burkina Faso</option>
                                    <option>Burma</option>
                                    <option>Burundi</option>
                                    <option>Cambodia</option>
                                    <option>Cameroon</option>
                                    <option>Canada</option>
                                    <option>Canadian Dependencies</option>
                                    <option>Cape Verde</option>
                                    <option>Caroline Islands</option>
                                    <option>Cayman Islands</option>
                                    <option>Central African Republic</option>
                                    <option>Chad</option>
                                    <option>Chile</option>
                                    <option>China</option>
                                    <option>Christmas Island</option>
                                    <option>Ciskei</option>
                                    <option>Cocos (Keeling) Islands</option>
                                    <option>Colombia</option>
                                    <option>Comore</option>
                                    <option>Cook Islands</option>
                                    <option>Costa Rica</option>
                                    <option>Cote d'Ivoire</option>
                                    <option>Croatia</option>
                                    <option>Cuba</option>
                                    <option>Curaçao</option>
                                    <option>Cyprus</option>
                                    <option>Czech Republic</option>
                                    <option>Czechoslovakia</option>
                                    <option>Denmark</option>
                                    <option>Djibouti</option>
                                    <option>Dominica</option>
                                    <option>Dominican Republic</option>
                                    <option>Easter island</option>
                                    <option>Ecuador</option>
                                    <option>Egypt</option>
                                    <option>El Salvador</option>
                                    <option>Equatorial Guinea</option>
                                    <option>Eritrea</option>
                                    <option>Estonia</option>
                                    <option>Ethiopia</option>
                                    <option>Falkland Islands (Malvinas)</option>
                                    <option>Faroe Islands</option>
                                    <option>Federated States Of Micronesia</option>
                                    <option>Fiji Islands</option>
                                    <option>Finland</option>
                                    <option>France</option>
                                    <option>French Dependencies</option>
                                    <option>French Guyana</option>
                                    <option>French Polynesia</option>
                                    <option>French Somalia</option>
                                    <option>Gabon</option>
                                    <option>Gambia</option>
                                    <option>Gaza</option>
                                    <option>Georgia</option>
                                    <option>Germany</option>
                                    <option>Germany democratic republic</option>
                                    <option>Ghana</option>
                                    <option>Gibraltar</option>
                                    <option>Greece</option>
                                    <option>Greenland</option>
                                    <option>Grenada</option>
                                    <option>Guadeloupe</option>
                                    <option>Guam</option>
                                    <option>Guatemala</option>
                                    <option>Guinea</option>
                                    <option>Guinea-Bissau</option>
                                    <option>Guyana</option>
                                    <option>Haiti</option>
                                    <option>Honduras</option>
                                    <option>Hong Kong</option>
                                    <option>Hungary</option>
                                    <option>Iceland</option>
                                    <option>IFNI</option>
                                    <option>India</option>
                                    <option>Indonesia</option>
                                    <option>Iran, Islamic Republic of</option>
                                    <option>Iraq</option>
                                    <option>Ireland</option>
                                    <option>Isle of Man</option>
                                    <option>Israel</option>
                                    <option>Italy</option>
                                    <option>Jamaica</option>
                                    <option>Japan</option>
                                    <option>Jordan</option>
                                    <option>Kazakhstan</option>
                                    <option>Kenya</option>
                                    <option>Kingdom of Lesotho</option>
                                    <option>Kiribati</option>
                                    <option>Kiribati and Tuvalu Countries</option>
                                    <option>Kosovo</option>
                                    <option>Kuwait</option>
                                    <option>Kyrgyzstan</option>
                                    <option>La Reunion Island</option>
                                    <option>Laos</option>
                                    <option>Latvia</option>
                                    <option>Lebanon</option>
                                    <option>Lesotho</option>
                                    <option>Liberia</option>
                                    <option>Libya</option>
                                    <option>Liechtenstein</option>
                                    <option>Lithuania</option>
                                    <option>Luxembourg</option>
                                    <option>Macao</option>
                                    <option>Macquarie Islands</option>
                                    <option>Madagascar</option>
                                    <option>Malawi</option>
                                    <option>Malaysia</option>
                                    <option>Maldives</option>
                                    <option>Mali</option>
                                    <option>Malta</option>
                                    <option>Marcus Islands</option>
                                    <option>Marshall Islands</option>
                                    <option>Martinique</option>
                                    <option>Mauritania</option>
                                    <option>Mauritius</option>
                                    <option>Mayotte</option>
                                    <option>Mexico</option>
                                    <option>Midway Islands</option>
                                    <option>Monaco</option>
                                    <option>Mongolia</option>
                                    <option>Montenegro</option>
                                    <option>Montserrat</option>
                                    <option>Morocco</option>
                                    <option>Mozambique</option>
                                    <option>Nauru</option>
                                    <option>Neozeland Dependencies</option>
                                    <option>Nepal</option>
                                    <option>Netherlands</option>
                                    <option>New Caledonia</option>
                                    <option>New Guinea</option>
                                    <option>New Zealand</option>
                                    <option>Nicaragua</option>
                                    <option>Niger</option>
                                    <option>Nigeria</option>
                                    <option>Niue</option>
                                    <option>Norfolk Island</option>
                                    <option>Norman Islands</option>
                                    <option>North Korea</option>
                                    <option>North Vietnam</option>
                                    <option>Northern Mariana Islands</option>
                                    <option>Northern Sahara</option>
                                    <option>Norway</option>
                                    <option>Norwegian Dependencies</option>
                                    <option>Oman</option>
                                    <option>Pakistan</option>
                                    <option>Palau</option>
                                    <option>Palestinian Territory, Occupied</option>
                                    <option>Panama</option>
                                    <option>Panama Canal Zone</option>
                                    <option>Papua</option>
                                    <option>Papua New Guinea</option>
                                    <option>Paraguay</option>
                                    <option>Peru</option>
                                    <option>Philippines</option>
                                    <option>Pitcairn</option>
                                    <option>Poland</option>
                                    <option>Portugal</option>
                                    <option>Puerto Rico</option>
                                    <option>Qatar</option>
                                    <option>Republic Of Moldova</option>
                                    <option>Republic of Venda</option>
                                    <option>Republic of Yemen</option>
                                    <option>Romania</option>
                                    <option>Russian Dependencies</option>
                                    <option>Russian Federation</option>
                                    <option>Rwanda</option>
                                    <option>Ryukyu Islands</option>
                                    <option>Saint Helena, Ascension And Tristan Da Cunha</option>
                                    <option>Saint Kitts And Nevis</option>
                                    <option>Saint Lucia</option>
                                    <option>Saint Pierre And Miquelon</option>
                                    <option>Saint Vincent And The Grenadines</option>
                                    <option>Samoa</option>
                                    <option>San Marino</option>
                                    <option>Sao Tome And Principe</option>
                                    <option>Saudi Arabia</option>
                                    <option>Senegal</option>
                                    <option>Serbia</option>
                                    <option>Serbia - Montenegro</option>
                                    <option>Serbia and Montenegro</option>
                                    <option>Seychelles</option>
                                    <option>Sierra Leone</option>
                                    <option>Singapore</option>
                                    <option>Slovakia</option>
                                    <option>Slovenia</option>
                                    <option>Solomon</option>
                                    <option>Solomon Islands</option>
                                    <option>Somalia</option>
                                    <option>South Africa</option>
                                    <option>South African Dependecies</option>
                                    <option>South Korea</option>
                                    <option>South Sudan</option>
                                    <option>South Vietnam</option>
                                    <option>Southern Sahara</option>
                                    <option>South-West Africa (Republic of Namibia)</option>
                                    <option>Spain</option>
                                    <option>Spanish Sahara</option>
                                    <option>Sri Lanka</option>
                                    <option>Sudan</option>
                                    <option>Suriname</option>
                                    <option>Swaziland</option>
                                    <option>Sweden</option>
                                    <option>Switzerland</option>
                                    <option>Syria</option>
                                    <option>Taiwan</option>
                                    <option>Tajikistan</option>
                                    <option>Tanganyika</option>
                                    <option>Tanzania</option>
                                    <option>Thailand</option>
                                    <option>Congo</option>
                                    <option>The Democratic Republic Of The Congo</option>
                                    <option>The Former Yugoslav Republic Of Macedonia</option>
                                    <option>Timor-Leste</option>
                                    <option>Togo</option>
                                    <option>Tokelau</option>
                                    <option>Tonga</option>
                                    <option>Transkei</option>
                                    <option>Trinidad And Tobago</option>
                                    <option>Tunisia</option>
                                    <option>Turkey</option>
                                    <option>Turkmenistan</option>
                                    <option>Turks And Caicos Islands</option>
                                    <option>Tuvalu</option>
                                    <option>Uganda</option>
                                    <option>Ukraine</option>
                                    <option>Union of Soviet Socialist Republic</option>
                                    <option>United Arab Emirates</option>
                                    <option>United Kingdom of Great Britain and Northern Ireland</option>
                                    <option>United States</option>
                                    <option>Uruguay</option>
                                    <option>Us Dependencies</option>
                                    <option>Uzbekistan</option>
                                    <option>Vanuatu</option>
                                    <option>Vatican City State</option>
                                    <option>Venezuela</option>
                                    <option>Vietnam</option>
                                    <option>Virgin Islands, U.S.</option>
                                    <option>Wallis And Futuna</option>
                                    <option>Western Irian</option>
                                    <option>Yemen</option>
                                    <option>Zambia</option>
                                    <option>Zanzibar</option>
                                    <option>Zimbabwe</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <div className="col-md-4 text-black">
                            <div className="form-group">
                                <label className="text-black form-label" htmlFor="selectedDate">Birth date</label>
                                <DatePicker
                                    selected={this.state.user.birthdate}
                                    onChange={this.handleDateChange}
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>

                        <div className="col-md-3 col-md-3">
                            <label htmlFor="user_linkedin_lbspa">LinkedIn</label>
                            <InputGroup>
                                <FormControl
                                    id="user_linkedin_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.linkedin}
                                    onChange={event => this.handleChange(event, "linkedin")}
                                    onSelect={event => this.handleChange(event, "linkedin")}
                                />
                            </InputGroup>
                        </div>

                        <div className="col-md-3 col-md-3">
                            <label htmlFor="user_email_lbspa">Personal Email</label>
                            <InputGroup>
                                <FormControl
                                    id="user_email_lbspa"
                                    aria-describedby="user-input"
                                    type="email"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.email}
                                    onChange={event => this.handleChange(event, "email")}
                                    onSelect={event => this.handleChange(event, "email")}
                                />
                            </InputGroup>
                        </div>

                        <div className="col-md-3 col-md-3">
                            <label htmlFor="user_skype_lbspa">Skype</label>
                            <InputGroup>
                                <FormControl
                                    id="user_skype_lbspa"
                                    aria-describedby="user-input"
                                    type="text"
                                    className="form-control width-full text-primary"
                                    defaultValue={this.state.user.skype}
                                    onChange={event => this.handleChange(event, "skype")}
                                    onSelect={event => this.handleChange(event, "skype")}
                                />
                            </InputGroup>
                        </div>
                        */}
                    </div>

                    <div className="row profile-row">
                        <div className="col-md-12 my-3 text-right">
                            <button type="button" className="btn btn-lg btn-link px-0 text-secondary" onClick={this.updateUser} >
                                Save Changes
                            </button>
                        </div>
                    </div>

                </Modal>
            </div>
        )
    }
}

export default ModalComponent;
