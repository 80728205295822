import React, { Component } from 'react';
import API from "../../utils/API/api";
import DOMPurify from "dompurify";
import "./Bullettin.scss";
import history from './../../../utils/history'

class Bullettin extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            bullettinData: []
        }

        this.detailsBullettin = this.detailsBullettin.bind(this);
    }

    async componentDidMount() { 
        try {
            const response = await API.get("/wp/v2/posts");

            let tempData = [];
            response.data.map(value => {
                const tempObj = {
                    id: value.id,
                    title: value.title.rendered,
                    date: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(value.date_gmt)),
                    text: value.excerpt.rendered === "" ? value.content.rendered : value.excerpt.rendered
                }
                tempData = [...tempData, tempObj];

            });
            this.setState({
                bullettinData: tempData
            });
        } catch(error) {
            console.log("Error:", error);
        }
    }

    detailsBullettin(event, id) {
        event.preventDefault();
        history.push('/announcements-details/' + id);
    }

    render() {

        return (
            <div className="container box-shadowed bg-white">

                <div className="row">
                    <div className="col-12 blue-line"></div>
                    <div className="col-12 text-left text-primary pl-20 pt-30 pb-30 fs-26">
                        Posts
                    </div>
                    <div className="col-12 container-bullettin">
                        {this.state.bullettinData.map( (e,index) => {
                                return (
                                    <div 
                                        key={e.title+index.toString()} 
                                        onClick={event => this.detailsBullettin(event, e.id)}
                                        className="row pt-20 pb-20 bg-light-gray border-bottom cursor-pointer fs-20">
                                        <div className="col-10 pl-20 text-left">
                                            <strong><h3 className="font-weight-bold bg-light-gray text-primary text-left" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(e.title) }} /></strong><br/>
                                            <p className="date-subtitle">{e.date}</p>
                                            <p className="text-black text-left" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(e.text) }} />
                                        </div>
                                        <div className="col-2 pr-20 text-center my-auto">
                                            <span className="arrow-right active right"></span>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }

}

export default Bullettin;

