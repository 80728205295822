import React from 'react';
import { connect } from "react-redux";
import ProfilePageEdit from "../../components/profilePage/ProfilePageEdit";


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
}
class ProfilePageEditController extends React.Component{
    constructor(props,context) {
        super(props, context);

        this.goProfileView = this.goProfileView.bind(this);


        this.state = {}
    }


    goProfileView(){
        this.props.history.push("/profile");
    }




    render(){
        return (
            <ProfilePageEdit  goProfileView={this.goProfileView} checkUserComplete={this.props.checkUserComplete}/>

        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProfilePageEditController);
