import React from 'react';
import InputSearch from '../utils/inputSearch/InputSearch';
import API from "../utils/API/api";


const Header = (props) => {



            //check if surveys is admitted since it is the only component in the header that can be not visible
    //also check if the component is null since it is possible by design
    const surveysComponent = props.layout.body.find(element => element ? (element.name === 'Surveys') : false);
    const isSurveysComponentVisible = surveysComponent ? true : false;


    return (
        <React.Fragment>
            {/*DESKTOP*/}
            <nav className="navbar navbar-expand-xl navbar-light bg-white sticky-top pt-0 pb-0 hidden-mobile">
                <span onClick={() => props.onChangeSelected(0)} className="header-logo1 cursor-pointer"></span>
                <div className="collapse navbar-collapse ml-120">
                    <ul className={props.selected === 0 ? "navbar-nav ml-20 nav-active " : "navbar-nav ml-20"}>
                        <li onClick={() => props.onChangeSelected(0)} className="nav-item text-center cursor-pointer text-primary fs-18">
                            <span className="home active center"></span><br/>Home
                        </li>
                    </ul>
                    <ul className={props.selected === 1 ? "navbar-nav ml-20 nav-active" : "navbar-nav ml-20"}>
                        <li onClick={() => props.onChangeSelected(1)} className="nav-item text-center cursor-pointer text-primary fs-18">
                            <span className="profile active center"></span><br />My Profile
                        </li>
                    </ul>
                    <ul className={props.selected === 2 ? "navbar-nav ml-20 nav-active" : "navbar-nav ml-20"}>
                        <li onClick={() => props.onChangeSelected(2)} className="nav-item text-center cursor-pointer text-primary fs-18">
                            <span className="performance active center"></span><br />My Performance
                        </li>
                    </ul>
                    {
                        isSurveysComponentVisible ? (
                            <ul onClick={() => props.onChangeSelected(3)} className={props.selected === 3 ? "navbar-nav ml-20 nav-active" : "navbar-nav ml-20"}>
                                <li className="nav-item text-center cursor-pointer">
                                    <span className="surveys active center"></span><br />Surveys
                                </li>
                            </ul>
                        ) : ''
                    }
                </div>

                <InputSearch  value={props.search}
                              placeholder={props.searchPlaceholder}
                              onChange={text => props.onChangeSearch(text)}
                              onReset={() => props.onResetSearch()}
                              onSubmit={() => props.onSearch()}/>
                {
                    //if there is the parameter it means that the program has been built wit v-options
                    //and each v-option refers to a layout for the custom version (student from company)
                    process.env.REACT_APP_TYPE ? (
                        <div className={"image "} style={{ background: "red", width: "120px", height: "120px" }}>{/*TODO*/}</div>
                    ) : ''
                }

            </nav>

            {/*MOBILE*/}
            <nav className="navbar show-mobile bg-white mt-10">
                <span onClick={() => props.onChangeSelected(0)} className="header-logo1 cursor-pointer"></span>
                <InputSearch  value={props.search}
                              placeholder={props.searchPlaceholder}
                              onChange={text => props.onChangeSearch(text)}
                              onReset={() => props.onResetSearch()}
                              onSubmit={() => props.onSearch()}/>
            </nav>
            <nav className="show-mobile bg-white container-header-menu-mobile sticky-top ">
                <div className="row">
                    <div onClick={() => props.onChangeSelected(0)} className={"col-3 d-flex justify-content-center cursor-pointer"}>
                        <div className={(props.selected === 0 ? "nav-active " : "") + " text-center"}>
                            <span className="home active center"></span>
                        </div>
                    </div>
                    <div onClick={() => props.onChangeSelected(2)} className={"col-3 d-flex justify-content-center cursor-pointer"}>
                        <div className={(props.selected === 2 ? "nav-active " : "") + " text-center"}>
                            <span className="performance active center"></span>
                        </div>
                    </div>
                    {
                        isSurveysComponentVisible ? (
                            <div onClick={() => props.onChangeSelected(3)} className={"col-3 d-flex justify-content-center cursor-pointer"}>
                                <div className={(props.selected === 3 ? "nav-active " : "") + " text-center"}>
                                    <span className="surveys active center"></span>
                                </div>
                            </div>
                        ) : ''
                    }
                    <div onClick={() => props.onChangeSelected(1)} className={"col-3 d-flex justify-content-center cursor-pointer"}>
                        <div className={(props.selected === 1 ? "nav-active " : "") + " text-center"}>
                            <span className="profile active center"></span>
                        </div>
                    </div>

                </div>
            </nav>
        </React.Fragment>
    )

}

export default Header;

