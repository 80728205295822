import React from 'react';
import {connect} from "react-redux";
import ModalComponent from '../../components/modal/Modal'
import {setSelected} from '../../actions/header';


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setSelected: selected => dispatch(setSelected(selected))
    };
}

class ModalController extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.state = {}
    }





render() {
  return (
      <ModalComponent/>
  );

}
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalController);
