import React from 'react';
import API from "../utils/API/api";
import {search} from "../../actions/data";
import {Link} from "react-router-dom";



class SearchResults extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
        };
        this.searchresults=[];
        this.query='';
        this.count='';
    }

    componentDidMount = async() => {

        try {
            this.query = localStorage.getItem('search-query');
            this.searchresults = await API.get("/wp/v2/news", {search: this.query});
            this.searchresults = this.searchresults.data;
            this.count=this.searchresults.length;
            this.setState({
                ...this.state,
                ...{
                    isLoaded: true
                }
            });

        } catch (e) {
            console.log("error", e);
        }
    }


    render() {
        console.log(search);
        //this search value comes from   type: SEARCH_RESULTS_LOADED,    payload: [   "ciao1",] index.js // redux

        return (
            <div className="container-fluid main-container bg-lined bg-gray">
                <div className="container no-max-width pt-10">
                    <div className="row bg-white">
                        <div className="col-12 blue-line"></div>
                    </div>
                    <div className="row bg-white">
                        <div className="col-12 text-left text-black pt-35 pb-35 fs-32 pl-30 pr-30">
                            Your search <strong>{this.query}</strong> returned <strong>{this.count}</strong> results
                        </div>
                    </div>

                    <div className="row bg-white">
                        <div className="container-results">

                    {  this.searchresults.map((value) => {
                        return (

                                    <div className="row border-bottom pt-40 pb-40">
                                        <div className="col-2 text-left pl-30">
                                            <img src={value.acf.news_featured_image_lbspa.url} className="image float-left img-search" ></img>
                                        </div>
                                            <div className="col-10 text-left pl-90 my-auto">
                                            12th June 2019 | <span
                                            className={"text-primary"}><strong>Category</strong></span> | <span
                                            className={"text-black"}><strong>To be read</strong></span>
                                            <br/>
                                            <span>  <Link  className="fs-30 text-primary" to={`/news-details/${value.id}`}><strong>{value.title.rendered}</strong></Link></span>
                                            <br/>
                                            <span className="characters-overflow">
                                                    <span>{value.acf.news_excerpt_lbspa}</span>
                                                            <React.Fragment>
                                                                <span>... </span>
                                                                <span onClick={() => console.log("read more")}
                                                                      >
                                                                    <strong> <Link   className={"btn btn-secondary width-140 height-30 border-radius-50 fs-18 my-auto line-height-20"}  to={`/news-details/${value.id}`}>READ MORE</Link></strong></span>
                                                            </React.Fragment>


                                                </span>
                                        </div>
                                    </div>


                        );
                    })}  </div>
                    </div>



                </div>
            </div>


        )
    }
}

export default SearchResults;
