import { combineReducers } from 'redux';
import layoutReducer from "./layout/";
import dataReducer from "./data/";
import headerReducer from "./header/";
import {i18nState} from "redux-i18n";
 
const rootReducer = combineReducers({
  layout : layoutReducer,
  data : dataReducer,
  header : headerReducer,
  i18nState
});

export default rootReducer;