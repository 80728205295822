import React from 'react';
import { connect } from "react-redux";
import Header from '../../components/header/Header';
import { search  } from '../../actions/data';
import { setSearchText , setSelected  } from '../../actions/header';
import queryString from 'query-string';


const mapStateToProps = state => {
    return { 
        layout: state.layout,
        searchText : state.header.searchText,
        selected : state.header.selected,
        placeholder : state.header.placeholder
    };
};

const mapDispatchToProps = dispatch => {
    return {
      search: text => dispatch(search(text)),
      setSearchText: text => dispatch(setSearchText(text)),
      setSelected: selected => dispatch(setSelected(selected))
    };
}

class HeaderController extends React.Component {

    constructor(props,context){
        super(props,context);
        
        this.onSearch = this.onSearch.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onChangeSelected = this.onChangeSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);

        this.state = {};
    }

    componentWillMount(){
        //if the page is reloaded it is necessary to set the header state: input text = query string param
        const qparams = queryString.parse(this.props.history.location.search);
        if( this.props.history.location.pathname === '/search' && qparams.text ){
            this.props.setSearchText(qparams.text);
        }

        //keep the blue line correctly positioned
        switch(this.props.history.location.pathname){
            case '/profile' : {
                this.props.setSelected(1);
                break;
            }
            case '/performance' : {
                this.props.setSelected(2);
                break;
            }
            case '/surveys' : {
                this.props.setSelected(3);
                break;
            }
            default : break;
        }
    }
    
    onSearch(){
        this.props.search(this.props.searchText);
        //page = home = 0
        this.props.setSelected(0);
        this.props.history.push("/search?text=" + this.props.searchText);
    }
       
    onChangeSelected(selected){
        this.props.setSelected(selected);
        this.props.setSearchText('');
        switch(selected){
            case 0 : {
                this.props.history.push("/");
                break;
            }
            case 1 : {
                this.props.history.push("/profile");
                break;
            }
            case 2 : {
                this.props.history.push("/performance");
                break;
            }
            case 3 : {
                this.props.history.push("/surveys");
                break;
            }
            default : break;
        }
    }

    onChangeSearch(search){
        this.props.setSearchText(search);
    }

    onResetSearch(){
        this.props.setSearchText('');
        //come back only if the current url is search
        if(this.props.history.location.pathname === '/search')
            this.props.history.push('/');
    }

    render(){
        return (
            <Header selected={this.props.selected}
                    layout={this.props.layout}
                    search={this.props.searchText}
                    searchPlaceholder={this.props.placeholder}
                    onChangeSearch={this.onChangeSearch}
                    onResetSearch={this.onResetSearch}
                    onSearch={this.onSearch}
                    onChangeSelected={this.onChangeSelected}/>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderController);
