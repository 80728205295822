import Bullettin from '../../../components/home/bullettin/Bullettin';
import News from '../../../components/home/news/News';
import Profile from '../../../components/home/profile/Profile';
import GoToCourseMaterials from '../../../components/home/goToCourseMaterial/GoToCourseMaterial';

const v3 = {
    header : {},
    body : [
        { name : 'Profile' , component : Profile},
        { name : 'News' , component : News},
        { name : 'GoToCourseMaterials' , component : GoToCourseMaterials},
        { name : 'Bullettin' , component : Bullettin}
    ]
}

export default v3;