import React from 'react';
import { connect } from "react-redux";
import BullettinDetail from "../../components/home/bullettin/BullettinDetails";


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
}
class BullettinDetailController extends React.Component{
    constructor(props,context) {
        super(props, context);

        this.goHome = this.goHome.bind(this);


        this.state = {}
    }


    goHome(){
        this.props.history.push("/");
    }   //na ginei implement sto component i function




    render(){
        return (
            <BullettinDetail  goHome={this.goHome}/>

        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(BullettinDetailController);