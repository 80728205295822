import { 
    SET_HEADER_SEARCH_TEXT,
    SET_HEADER_SELECTED
} from "../../constants/index";

/**
 * @param {string} text set searched text as a global state variable
 */
const setSearchText = text => {
    return { 
        type: SET_HEADER_SEARCH_TEXT, 
        payload: text
    }
}

/**
 * @param {integer} selected index of the item to selected in the header (ex: my profile , my performance ecc)
 */
const setSelected = selected => {
    return { 
        type: SET_HEADER_SELECTED, 
        payload: selected
    }
}



export {
    setSearchText,
    setSelected,
};
