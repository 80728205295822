import{
    SET_HEADER_SEARCH_TEXT,
    SET_HEADER_SELECTED
} from "../../constants/index";

const initialState = {
    searchText : '',
    selected : 0,
    placeholder : 'What are you looking for?'
};

const headerReducer = (state = initialState, action) => {
    console.log('reducer action', action.payload);
    switch(action.type){
        case SET_HEADER_SEARCH_TEXT:
            return {
                searchText : action.payload,
                selected : state.selected,
                placeholder : state.placeholder
            }
        case SET_HEADER_SELECTED: {
            return {
                searchText : state.searchText,
                selected : action.payload,
                placeholder : state.placeholder
            }
        }
        default: return state;
    }
}

export default headerReducer;
