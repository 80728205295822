import { LAYOUT_LOADED } from "../../constants/index";
import full from '../../config/layouts/full/';
import v0 from '../../config/layouts/v0/';
import v1 from '../../config/layouts/v1/';
import v2 from '../../config/layouts/v2/';
import v3 from '../../config/layouts/v3/';
import v4 from '../../config/layouts/v4/';
import v5 from '../../config/layouts/v5/';
import v6 from '../../config/layouts/v6/';

const getLayout = type => {
    switch(type){
        case 'v0' : { return { type: LAYOUT_LOADED , payload: v0 }; }
        case 'v1' : { return { type: LAYOUT_LOADED , payload: v1 }; }
        case 'v2' : { return { type: LAYOUT_LOADED , payload: v2 }; }
        case 'v3' : { return { type: LAYOUT_LOADED , payload: v3 }; }
        case 'v4' : { return { type: LAYOUT_LOADED , payload: v4 }; }
        case 'v5' : { return { type: LAYOUT_LOADED , payload: v5 }; }
        case 'v6' : { return { type: LAYOUT_LOADED , payload: v6 }; }
        default :   { return { type: LAYOUT_LOADED , payload: full }; }
    }   
}

export {
    getLayout
}