import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DOMPurify from 'dompurify';
import API from '../../utils/API/api';
import './BullettinDetail.scss'

class BullettinDetail extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            announcementDetails: {
                id: null,
                title: '',
                date: '',
                content: ''
            }
        };
    }

    async componentDidMount() {
        try {
            const announcmentId = this.props.match.params.id
            const response = await API.get("/wp/v2/posts/" + announcmentId);
            const responseData = response.data;
            this.setState({
                announcementDetails: {
                    id: responseData.id,
                    title: responseData.title.rendered,
                    date: new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(responseData.date_gmt)),
                    content: responseData.content.rendered
                }
            })
        } catch(error) {
            console.log("Error:", error);
        }
    }

    goBack() {
        this.props.goHome();
    }

    render() {

        return (
            <div>
                <div className="container-fluid main-container bg-lined bg-gray">
                    <div className="row bg-white">
                        <div className="col-12 blue-line"></div>
                    </div>

                    <div className="row bg-white news-navbar">
                        <div className="col-sm-12 col-md-7 d-none d-sm-flex align-items-center h-100 text-left text-primary">
                            <div onClick={() => this.goBack()} className="d-flex cursor-pointer">
                                <span className="arrow-left active left align-self-center"></span>
                                <span className="ml-15 fs-22 align-self-center font-weight-bold">Home</span>
                            </div>
                        </div>

                    </div>

                    <div className="row bg-white">
                        <div className="col-sm-12 col-md-7 offset-sm-0 offset-md-3 text-left">
                            <div className="row">
                                <div className="col-sm-12 col-md-7">
                                    {<h1 className="text-primary text-left"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.announcementDetails.title) }}
                                    />}
                                </div>

                                <div className="col-sm-12 col-md-5 text-right text-black">
                                    <span> {this.state.announcementDetails.date} </span> 
                                </div>

                                <div className="col-sm-12 text-black my-2">
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.announcementDetails.content) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(BullettinDetail);

BullettinDetail.contextTypes = {
  t: PropTypes.func
}
