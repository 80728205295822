import React from "react";
import _ from "lodash";
import Select from 'react-select';
import ProgressBar from "../utils/progressBar/ProgressBar";
import API from "../utils/API/api";
import "./PerformancePage.scss";

class PerformancePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.goBack = this.goBack.bind(this);
    this.dateRange = this.setDateRange(12);
    // this.selectOptions =  this.dateRange.map(value => ({ value: value, label: value }))
    this.state = {
      id: null,
      progress: 0,
      selectedDate: this.dateRange[0],
      points: {},
      rankRequirements: {},      
      previousMonth: '',
      news: 0,
      likes: 0,
      comments: 0,
      newsPercentage: 0,
      likesPercentage: 0,
      commentsPercentage: 0,
      untilNextLevelProgress: 0
    };
  }

  setDateRange = totalMonths => {
    const range = [];
    const today = new Date();
    let value = `${today.getMonth() + 1}-${today.getFullYear()}`;
    range.push({value: value, label: value});
    for (let i = 0; i < totalMonths; i++) {
      today.setMonth(today.getMonth() - 1);
      today.setMonth(today.getMonth());
      value = `${today.getMonth() + 1}-${today.getFullYear()}`;
      range.push({value: value, label: value});
    }
    console.log('range: ', range);
    return range;
  };

  componentDidMount = async () => {
    try {
      let userMe = await API.get("/wp/v2/users/me");
      userMe = userMe.data;

      let acf = {};
      acf = userMe.acf;
      /* count how many acf fields are being filled, out of 21, to calculate the progress level */
      let counter = Object.keys(acf);
      let realCounter = counter.length;
      if (_.includes(counter, "user_not_employed_lbspa")) {
        realCounter--;
        _.remove(counter, key => key === "user_not_employed_lbspa");
      }
      if (_.includes(counter, "user_job_title_lbspa")) {
        realCounter--;
        _.remove(counter, key => key === "user_job_title_lbspa");
      }
      if (_.includes(counter, "user_company_lbspa")) {
        realCounter--;
        _.remove(counter, key => key === "user_company_lbspa");
      }
      counter.forEach(key => {
        if (acf[key] === "" || acf[key] === null || acf[key] === undefined)
          realCounter--;
      });
      const progress = parseInt((100 / 18) * realCounter, 10);

      const points = _.has(userMe, "points") ? userMe.points : {};
      const rankRequirements = _.has(userMe, "rank_requirements") ? userMe.rank_requirements : {}; 

      const untilNextLevel = {
        read: ((rankRequirements.read - points.read) >= 0) ? (rankRequirements.read - points.read) : 0,
        like: ((rankRequirements.like - points.like) >= 0) ? (rankRequirements.like - points.like) : 0,
        comment: ((rankRequirements.comment - points.comment) >= 0) ? (rankRequirements.comment - points.comment) : 0,
      }
 
      let totalReqNext = 0;
      totalReqNext += userMe.rank_requirements.read;
      totalReqNext += userMe.rank_requirements.comment;
      totalReqNext += userMe.rank_requirements.like;
 
      let totalUserNext = 0;
      totalUserNext += untilNextLevel.read;
      totalUserNext += untilNextLevel.comment;
      totalUserNext += untilNextLevel.like;
 
      let untilNextLevelProgress = Math.round(100 - ((100 / totalReqNext) * totalUserNext));
 
      let rankLabel = userMe.rank;
      let nextRankLabel = userMe.next_rank;

      this.setState({
        id: userMe.id,
        progress: progress,
        points: points,
        rankLabel: rankLabel,
        nextRankLabel: nextRankLabel,
        rankRequirements: rankRequirements,
        untilNextLevelProgress: untilNextLevelProgress  
      });
    }
      catch(error) {
        console.log('error', error);
      }
    this.changeDateRange(this.state.selectedDate);
  }

  goBack() {
    this.props.onBack();
  }

  changeDateRange = async (selectedDate) => {
    await this.setState({ selectedDate: selectedDate});
    try {      
      /* current user performance */
      const bodyCP = {
        user_id: this.state.id,
        date: this.state.selectedDate.value
      };
      let currentPerformance = await API.post("/custom-performance-resource/v1/", bodyCP);
      currentPerformance = currentPerformance.data;
      if (currentPerformance.length < 1) {
        currentPerformance = [
          { points_type: "comments", "points": 0 },
          { points_type: "reads", "points": 0 },
          { points_type: "likes", "points": 0 }
        ];
      }

      /* previous user performance */
      const currentTP = this.state.selectedDate.value;
      let previousTP = ["", ""];
      previousTP = currentTP.split("-");
      let month = parseInt(previousTP[0], 10);
      const previousMonth = month ? month - 1 : "";
      const bodyPP = {
        user_id: this.state.id,
        date: `${previousMonth}-${previousTP[1]}`
      };
      
      let previousPerformance = await API.post("/custom-performance-resource/v1/", bodyPP);
      previousPerformance = previousPerformance.data;     
      
      /* previous month data are marked with Prv suffix */
      let news = _.find(currentPerformance, val => val.points_type === 'reads' );
      news = (_.has(news, 'points_type')) ? parseInt(news['points'], 10) : 0;
      let newsPrv = _.find(previousPerformance, val => val.points_type === 'reads' );
      newsPrv = (_.has(newsPrv, 'points_type')) ? parseInt(newsPrv['points'], 10) : 0;
      let newsPercentage = 0;
      if (newsPrv !== 0) {
        newsPercentage = parseInt((((news / newsPrv) * 100) - 100), 10);
      } else {
        newsPercentage = news * 100;
      }  
      
      let likes = _.find(currentPerformance, val => val.points_type === 'likes' );
      likes = (_.has(likes, 'points_type')) ? parseInt(likes['points'], 10) : 0; 
      let likesPrv = _.find(previousPerformance, val => val.points_type === 'comments' );
      likesPrv = (_.has(likesPrv, 'points_type')) ? parseInt(likesPrv['points'], 10) : 0;
      let likesPercentage = 0;
      if (likesPrv !== 0) {
        likesPercentage = parseInt((((likes / likesPrv) * 100) - 100) ,10);
      } else {
        likesPercentage = likes * 100;
      }

      let comments = _.find(currentPerformance, val => val.points_type === 'comments' );
      comments = (_.has(comments, 'points_type')) ? parseInt(comments['points'], 10) : 0;
      let commentsPrv = _.find(previousPerformance, val => val.points_type === 'comments' );
      commentsPrv = (_.has(commentsPrv, 'points_type')) ? parseInt(commentsPrv['points'], 10) : 0;
      let commentsPercentage = 0;
      if (commentsPrv !== 0) { 
        commentsPercentage = parseInt((((comments / commentsPrv) * 100) - 100) ,10);
      } else {
        commentsPercentage = comments * 100;
      }

      this.setState({
        previousMonth: previousMonth,
        news: news,
        likes: likes,
        comments: comments,
        newsPercentage: newsPercentage,
        likesPercentage: likesPercentage,
        commentsPercentage: commentsPercentage
      });
      
      console.log('changeDateRange', this.state);
    } catch (e) {
      console.log("error", e);
    }
  }

  render() {    
    const toRead = (this.state.rankRequirements.read - this.state.points.read > 0) ? (this.state.rankRequirements.read - this.state.points.read) : 0;
    const toLike = (this.state.rankRequirements.like - this.state.points.like > 0) ? (this.state.rankRequirements.like - this.state.points.like) : 0;
    const toComment = (this.state.rankRequirements.read - this.state.points.comment > 0) ? (this.state.rankRequirements.comment - this.state.points.comment) : 0;

    let date  = new Date();
    date.setMonth(this.state.previousMonth - 1);    
    const month = date.toLocaleString('default', { month: 'long' });    

    const customStyles = {
      control: base => ({
        ...base,        
        backgroundColor: 'white',
        'border': 0,        
        'boxShadow': 0,
        '&:hover': {
           'border': 0
        }        
      }),
      option: base => ({
        ...base,
        '&:active': {'backgroundColor': '#003A70'},
        '&:hover': {'backgroundColor': '#003A70'},
        'backgroundColor': 'white',
        'color': '#cc8a00',      
      }),
      dropdownIndicator: base => ({
        ...base,
        color: "#003A70",
        "&:hover": {
          color: "#cc8a00"
        }
      }),
      singleValue: base => ({
        ...base,
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        color: "#003A70"
      })
    };
  
    return (
      <div className="container-fluid main-container bg-lined bg-gray">
        <div className="">
          <div className="row bg-white">
            <div className="col-12 blue-line"></div>
          </div>

          <div id="performance" className="row bg-white">
            <div className="col-md-9 col-6 border-right text-left text-primary">
              <div className="row">
                <div
                  className="d-none d-md-flex col-12 pt-10 cursor-pointer"
                  onClick={() => this.goBack()}
                >
                  <span className="arrow-left active left align-self-center"></span>
                  <span className="ml-15 fs-22 align-self-center font-weight-bold">
                    Home
                  </span>
                </div>
                <div className="col-12">
                  <h4 className="text-primary page-title"> My Performance </h4>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6 period text-black">
              <div className="row">
                <div className="col-12">Time period</div>
                <div className="col-12 text-primary">
                  <Select 
                    styles={customStyles}
                    defaultValue={this.state.selectedDate}
                    onChange={this.changeDateRange}
                    options={this.dateRange}                    
                  />                  
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-gray">
            <div
              id="performance-page-level"
              className="col-12 col-xl-8 border-right border-bottom"
            >
              <div
                id="level"
                className="row justify-content-center align-items-center"
              >
                <div id="level-header" className="col-12 col-md-11 offset-md-1">
                  <h4 className="h4 text-black text-left">My Level</h4>
                </div>
                <div className="col-3 text-center">
                  <span className="prize empty"></span>
                  <p className="text-center d-none d-xl-block">                    
                    {this.state.rankLabel} Level
                  </p>
                </div>
                <div className="col-6">
                  <div className="w-100 align-middle text-center text-primary">
                    <ProgressBar value={this.state.untilNextLevelProgress} />
                    <p className="mt-2">{this.state.untilNextLevelProgress} %</p>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <span className="prize full"></span>
                  <p className="text-center d-none d-xl-block">{this.state.nextRankLabel} Level</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-4 border-bottom pb-35 pt-35 ">
              <div className="row">
                <div className="col-12 text-black fs-28 fs-22-mobile pl-50 pl-15-mobile pr-15-mobile">
                  To reach the next level:
                </div>
              </div>
            
              <div className="row mt-60 mt-35-mobile">
                <div className="col-12 text-black fs-28 fs-22-mobile my-auto pl-50 pl-50 pl-25-mobile">
                  <div className="d-flex ">
                    <div className="text-primary fs-48 fs-32-mobile align-self-center mr-3 line-height-065 font-weight-bold">
                      {toRead}
                    </div>
                    <div className="align-self-center line-height-1">
                      News to Read
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-12 text-black fs-28 fs-22-mobile my-auto pl-50 pl-25-mobile">
                  <div className="d-flex ">
                    <div className="text-primary fs-48 fs-32-mobile align-self-center mr-3 line-height-065 font-weight-bold">
                      {toLike}
                    </div>
                    <div className="align-self-center line-height-1">
                      News to be liked
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-12 text-black fs-28 fs-22-mobile my-auto pl-50 pl-25-mobile">
                  <div className="d-flex ">
                    <div className="text-primary fs-48 fs-32-mobile align-self-center mr-3 line-height-065 font-weight-bold">
                    {toComment}
                    </div>
                    <div className="align-self-center line-height-1">
                      Comments on news
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row bg-gray">
            <div className="col-12 col-lg-4 border-bottom-mobile">
              <div className="row">
                <div className="col-12 text-center pt-35 fs-28 fs-22-mobile text-black line-height-1">
                  News Read
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-40 pt-20-mobile fs-112 text-primary line-height-1">
                  {this.state.news}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-10 fs-34 text-primary line-height-1">
                  <span className={(this.state.newsPercentage > 0) ? `up center` : `down center`}></span> 
                  <strong>{(this.state.newsPercentage > 0) ? `+${this.state.newsPercentage}%` : `${this.state.newsPercentage}%`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-20 fs-24 pb-60 pb-30-mobile text-black line-height-1">
                  {`Compared to ${month}`}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 border-right border-left no-border-right-mobile no-border-left-mobile border-bottom-mobile">
              <div className="row">
                <div className="col-12 text-center pt-35 fs-28 fs-22-mobile text-black line-height-1">
                  Likes
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-40 pt-20-mobile fs-112 text-primary line-height-1">
                  {this.state.likes}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-10 fs-34 text-primary line-height-1">
                  <span className={(this.state.newsPercentage > 0) ? `up center` : `down center`}></span>  
                  <strong>{(this.state.likesPercentage > 0) ? `+${this.state.likesPercentage}%` : `${this.state.likesPercentage}%`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-20 pb-60 pb-30-mobile fs-24 text-black line-height-1">
                  {`Compared to ${month}`}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-12 text-center pt-35 fs-28 fs-22-mobile text-black line-height-1">
                  Comments
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-40 pt-20-mobile fs-112 text-primary line-height-1">
                  {this.state.comments}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-10 fs-34 text-primary line-height-1">
                  <span className={(this.state.newsPercentage > 0) ? `up center` : `down center`}></span> 
                  <strong>{(this.state.commentsPercentage > 0) ? `+${this.state.commentsPercentage}%` : `${this.state.commentsPercentage}%`}</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center pt-20 pb-60 pb-30-mobile fs-24 text-black line-height-1">
                  {`Compared to ${month}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PerformancePage;
