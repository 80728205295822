import React from 'react';

const Main = (props) => {

    console.log('props', props);

    //get component in base based on the layout
    const ComponentPositionZero  = props.layout.body[0] ? props.layout.body[0].component : null;
    const ComponentPositionOne   = props.layout.body[1] ? props.layout.body[1].component : null;
    const ComponentPositionTwo   = props.layout.body[2] ? props.layout.body[2].component : null;
    const ComponentPositionThree = props.layout.body[3] ? props.layout.body[3].component : null;
    const ComponentPositionFour  = props.layout.body[4] ? props.layout.body[4].component : null;
    const ComponentPositionFive  = props.layout.body[5] ? props.layout.body[5].component : null ;

    return (

        <div className="container-fluid main-container bg-lined bg-gray">
            <div className="row">

                

                <div className="col-12 col-xl-3 text-center pl-0 pr-md-4 pr-0">
                    {
                        ComponentPositionZero ? (
                            <ComponentPositionZero onGoTo={ to => props.onGoTo(to)} />
                        ): ''
                    }
                    {
                        ComponentPositionFour ? (
                            <div className="mt-20 d-none d-xl-block">
                                <ComponentPositionFour onGoTo={ to => props.onGoTo(to) } />
                            </div>
                        ): ''
                    }
                </div>
                
                <div className="col-12 col-xl-6 text-center p-0 ">
                        {
                            ComponentPositionOne ? (
                                <ComponentPositionOne onGoTo={ to => props.onGoTo(to) } />
                            ): ''
                        }
                </div>
                
                <div className="col-12 col-xl-3 text-center pr-0 pl-4 pl-0-mobile">
                    {
                        ComponentPositionTwo ? (
                            <div className="d-none d-xl-block">
                                <ComponentPositionTwo onGoTo={ to => props.onGoTo(to) } />
                            </div>
                        ): ''
                    }
                    {
                        ComponentPositionThree ? (
                            <div className="mt-20 d-none d-xl-block">
                                <ComponentPositionThree onGoTo={ to => props.onGoTo(to) } />
                            </div>
                        ): ''
                    }
                    {
                        ComponentPositionFive ? (
                            <div className="mt-20 d-none d-xl-block">
                                <ComponentPositionFive onGoTo={ to => props.onGoTo(to) } />
                            </div>
                        ): ''
                    }
                </div>
            </div>    
        </div>  
    );
}

export default Main;
