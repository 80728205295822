import React from 'react';
import { connect } from "react-redux";
import Main from '../../../components/home/main/Main';
import {  setSelected  } from '../../../actions/header';


const mapStateToProps = state => {
    return { 
        layout: state.layout
    };
};
const mapDispatchToProps = dispatch => {
    return {
      setSelected: selected => dispatch(setSelected(selected))
    };
}
  

class MainController extends React.Component{
    constructor(props,context){
        super(props,context);

        this.onGoTo = this.onGoTo.bind(this);

        this.state = {}
    }

    onGoTo(to){
        //change the selected item in the header
        this.props.setSelected(to);
        switch(to){
            case 1 : {
                this.props.history.push("/profile");
                break;
            }
            case 2 : {
                this.props.history.push("/performance");
                break;
            }
            case 3 : {
                this.props.history.push("/surveys");
                break;
            }
            default : break;
        }
    }

    render(){
        return <Main    onGoTo={this.onGoTo}
                        layout={this.props.layout}/>
    }
    
}

export default connect(mapStateToProps,mapDispatchToProps)(MainController);
