import News from '../../../components/home/news/News';
import Profile from '../../../components/home/profile/Profile';
import Surveys from '../../../components/home/surveys/Surveys';

const v6 = {
    header : {},
    body : [
        { name : 'Profile' , component : Profile},
        { name : 'News' , component : News},
        { name : 'Surveys' , component : Surveys}
    ]
}

export default v6;