import React from 'react';
import { connect } from "react-redux";
import {setSelected} from "../../actions/header";
import SurveysDetailPage from "../../components/surveysPage/SurveysDetailPage";

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setSelected: selected => dispatch(setSelected(selected))
    };
}
class SurveysDetailPageController extends React.Component {

    constructor(props,context){
        super(props,context);

        this.onBack = this.onBack.bind(this);

        this.state = {
            isEditable : false,
            text : 'Mario'
        }
    }

    onBack(){

        //update the headerstatus (blue line) through redux
        this.props.setSelected(0)
        //come back to home
        this.props.history.push('/');

    }
    render(){
        return (
           <SurveysDetailPage  onBack={this.onBack}/>
        );

    }}

export default connect(mapStateToProps,mapDispatchToProps)(SurveysDetailPageController);
