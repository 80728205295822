import React from 'react';
import { connect } from "react-redux";
import SearchResults from '../../components/searchResults/SearchResults'


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
}

const SearchResultsController = props => {

    return (
        <SearchResults/>
    );
    
}

export default connect(mapStateToProps,mapDispatchToProps)(SearchResultsController);
