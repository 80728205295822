import React from "react";
import DOMPurify from "dompurify";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import DatePicker from "react-datepicker";
import _ from "lodash";
import API from "../utils/API/api";
import ProgressBar from "../utils/progressBar/ProgressBar";
import Autosuggest from 'react-autosuggest';
import "react-datepicker/dist/react-datepicker.css";
import "./ProfilePage.scss";

    let companySuggestion = [];

    /*Company Autocomplete*/
    const escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const autoGetSuggestions = value => {
      console.log('autoGetSuggestion called!');
      const escapedValue = escapeRegexCharacters(value.trim());
      if (escapedValue === '') {
        return [];
      }
      const regex = new RegExp('^' + escapedValue, 'i');
      const suggestions = companySuggestion.filter(company => regex.test(company));
      if (suggestions.length === 0) {
        return [
          { isAddNew: true }
        ];
      }
      return suggestions;
    }
    /*Company Autocomplete end */

class ProfilePageEdit extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.goProfile = this.goProfile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.userId = null;

    this.state = {
      isLoaded: false,
      selectedDate: null,
      notEmployed: 0,
      userID: null,
      user_name_lbspa: null,
      user_surname_lbspa: null,
      user_title_lbspa: null,
      user_nationality_lbspa: null,
      user_country_lbspa: null,
      user_personal_email_lbspa: null,
      user_ssn_lbspa: null,
      user_region_lbspa: null,
      user_city_lbspa: null,
      user_mobile_number_lbspa: null,
      user_office_number_lbspa: null,
      user_linkedin_lbspa: null,
      user_skype_lbspa: null,
      user_educational_degree_lbspa: null,
      user_learning_activities_lbspa: null,
      user_not_employed_lbspa: null,
      user_gender_lbspa: null,
      user_birthdate_lbspa: null,
      user_image_lbspa: null,
      user_company_lbspa: null,
      user_job_title_lbspa: null,
      user_toi_list: [],
      all_topics: [],
      companySuggestion: []
    };

  }

  async componentWillMount() {
    try {
      let userMe = await API.get("/wp/v2/users/me");
      userMe = userMe.data;

      let userTopics = (_.has(userMe, "acf.user_toi_list") && !_.isEmpty(userMe.acf.user_toi_list))
        ? userMe.acf.user_toi_list
        : [];
      console.log(userTopics);

      /* get all topics of interest */
      let allToI = await API.get("/wp/v2/topics_of_interest_tags");
      const pages = parseInt(allToI.headers["x-wp-totalpages"], 10);

      let allTopics = [];

      try {
        allToI = await this.getAllTopics(pages);

        if(allToI !== null) {
          allTopics = allToI.map(value => value.data);
          allTopics = allTopics.flat();
          allTopics = allTopics.map(value => ({
            id: value.id,
            name: value.name,
            selectedByUser: false
          }));

          allTopics.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
      } catch (error) {
        console.log(error);
      }

      if(userTopics !== null) {
        userTopics = userTopics.map(value => ({
          id: value.term_id,
          name: value.name,
          selectedByUser: true
        }));

        userTopics.sort((a, b) => (a.name > b.name ? 1 : -1));
      }

      /* update all topics list with the ones the user has selected */
      const updatedTopics = allTopics.map(topic => {
        const found = userTopics.find(
          userTopic => topic.name === userTopic.name
        );
        topic = found ? found : topic;
        return topic;
      });

      let acf = {};
      acf = userMe.acf;

      /* count how many acf fields are being filled, out of 18, to calculate the progress level */
      let counter = Object.keys(acf);
      console.log('start:',JSON.stringify(counter));
      let realCounter = counter.length;
      if (_.includes(counter, 'user_not_employed_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_not_employed_lbspa');
      }
      if (_.includes(counter, 'user_job_title_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_job_title_lbspa');
      }
      if (_.includes(counter, 'user_company_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_company_lbspa');
      }
      counter.forEach((key)  => {
        if (acf[key] === '' || acf[key] === null || acf[key] === undefined)
          realCounter--;
      });
      const progress = parseInt(((100 / 18) * realCounter),10);


      let birthDay = null;
      if(_.has(acf, 'user_birthdate_lbspa') && acf.user_birthdate_lbspa !== null && acf.user_birthdate_lbspa !== '') {
        birthDay = this.parseDate(acf.user_birthdate_lbspa);
      }

      let user_personal_email = null;
      if(_.has(acf, 'user_personal_email_lbspa') && acf.user_personal_email_lbspa !== null && acf.user_personal_email_lbspa !== '') {
        user_personal_email = acf.user_personal_email_lbspa;
      }

      /*Company Autocomplete*/
      if(_.has(userMe, 'user_company_suggest') && userMe.user_company_suggest !== null) {
        companySuggestion = userMe.user_company_suggest;
      }
      /*Company Autocomplete end */

      //let notEmployedInt = (acf.user_not_employed_lbspa === true) ? 0 : 1;

      this.setState({
        progress: progress,
        selectedDate: birthDay,
        notEmployed: acf.user_not_employed_lbspa,
        userID: userMe.id,
        user_personal_email_lbspa: user_personal_email,
        user_toi_list: userTopics,
        all_topics: updatedTopics,
        user_name_lbspa: acf.user_name_lbspa,
        user_surname_lbspa: acf.user_surname_lbspa,
        user_title_lbspa: acf.user_title_lbspa,
        user_nationality_lbspa: acf.user_nationality_lbspa,
        user_country_lbspa: acf.user_country_lbspa,
        user_ssn_lbspa: acf.user_ssn_lbspa,
        user_region_lbspa: acf.user_region_lbspa,
        user_city_lbspa: acf.user_city_lbspa,
        user_mobile_number_lbspa: acf.user_mobile_number_lbspa,
        user_office_number_lbspa: acf.user_office_number_lbspa,
        user_linkedin_lbspa: acf.user_linkedin_lbspa,
        user_skype_lbspa: acf.user_skype_lbspa,
        user_educational_degree_lbspa: acf.user_educational_degree_lbspa,
        user_learning_activities_lbspa: acf.user_learning_activities_lbspa,
        user_not_employed_lbspa: acf.user_not_employed_lbspa,
        user_gender_lbspa: acf.user_gender_lbspa,
        user_birthdate_lbspa: acf.user_birthdate_lbspa,
        user_image_lbspa: acf.user_image_lbspa,
        user_company_lbspa: acf.user_company_lbspa,
        user_job_title_lbspa: acf.user_job_title_lbspa,
        companySuggestion: companySuggestion
      });
    } catch (e) {
      console.log("error", e);
    }
  }

  getAllTopics = page => {
    const topics = [];
    let params = {};
    for (let i = 1; i <= page; ++i) {
      params = { page: i, order: "desc" };
      topics.push(API.get("/wp/v2/topics_of_interest_tags", params));
    }
    params = {};
    return Promise.all(topics);
  };

  goProfile() {
    this.props.goProfileView();
  }

  parseDate = input => {
    const parts = input.match(/(\d+)/g);
    // note parts[1]-1
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };

  handleDateChange = date => {
    this.setState({
      selectedDate: date
    });
  };

  handleNotEmployedChange = () => {
    this.setState({
      notEmployed: !this.state.notEmployed,
    });
  };

  handleTopic = (topic, index) => {
    const topics = this.state.all_topics;
    topics[index] = {
      id: topic.id,
      name: topic.name,
      selectedByUser: !topic.selectedByUser
    };
    this.setState({ all_topics: topics });
  };

  handleChange(event) {
    const tmp = event.target.value.match(/[^\s]/i);
    if (tmp !== null) {
      this.setState({
        [event.target.id]: event.target.value
      });
    } else {
      this.setState({
        [event.target.id]: ''
      });
    }
  }

  updateUser = async () => {
    const id = this.state.userID;
    const userFields = {};
    const allKeys = Object.keys(this.state);

    for (let k in allKeys) {
      const key = allKeys[k];
      if (
        key !== "selectedDate" &&
        key !== "notEmployed" &&
        key !== "isLoaded" &&
        key !== "user_toi_list" &&
        key !== "all_topics"
      )
        userFields[key] = this.state[key];
        console.log('userFields: ', JSON.stringify(userFields));
    }

    userFields.user_not_employed_lbspa = this.state.notEmployed;
    if (this.state.notEmployed) {
      userFields.user_company_lbspa = null;
      userFields.user_job_title_lbspa = null;
    }

    userFields.user_toi_list = this.state.all_topics.reduce(
      (filtered, topic) => {
        if (topic.selectedByUser) {
          filtered.push(topic.id);
        }
        return filtered;
      },
      []
    );
    const userEmail = this.state.user_personal_email_lbspa;

    userFields.user_birthdate_lbspa = null;

    if(this.state.selectedDate !== null) {
      userFields.user_birthdate_lbspa = this.state.selectedDate.toLocaleDateString();
    }

    try {
      const updateUser = await API.post("/acf/v3/users/" + id, { acf: userFields });
      //const updateUserEmail = await API.post('/wp/v2/users/' + id, {email: userEmail} );
      //console.log("res", updateUser, updateUserEmail);
      await this.props.checkUserComplete();
      this.props.goProfileView();
    } catch (error) {
      console.log(error);
    }
  };

  /*Company Autocomplete*/
  autoOnChange = (event, { newValue, method }) => {
    this.setState({
      user_company_lbspa: newValue
    });
  };

  autoGetSuggestionValue = suggestion => {
    if (suggestion.isAddNew) {
      return this.state.user_company_lbspa;
    }
    
    return suggestion;
  };

  autoRenderSuggestion = suggestion => {
    if (suggestion.isAddNew) {
      return (
        <span>
          [+] Add new: <strong>{this.state.user_company_lbspa}</strong>
        </span>
      );
    }

    return suggestion;
  };
  
  autoOnSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      companySuggestion: autoGetSuggestions(value)
    });
  };

  autoOnSuggestionsClearRequested = () => {
    this.setState({
      companySuggestion: []
    });
  };

  autoOnSuggestionSelected = (event, { suggestion }) => {
    if (suggestion.isAddNew) {
      console.log('Add new:', this.state.user_company_lbspa);
    }
  };
  /*Company Autocomplete end*/


  render() {
    /*Company Autocomplete*/
    const autoTheme = {
      container: 'autosuggest',
      input: 'form-control opacity width-full text-primary form-control',
      suggestionsContainer: 'dropdown',
      suggestionsList: `width-full dropdown-menu ${this.state.companySuggestion.length ? 'show' : ''}`,
      suggestion: 'dropdown-item',
      suggestionFocused: 'active'
    };

    let value = this.state.user_company_lbspa || '';
    const autoInputProps = {
      placeholder: "",
      value,
      onChange: this.autoOnChange
    };
    /*Company Autocomplete end*/
    const topics = this.state.all_topics.map((value, index) => {
      return (
        <span
          key={index.toString()}
          onClick={() => this.handleTopic(value, index)}
          className={
            value.selectedByUser
              ? "badge badge-pill badge-primary"
              : "badge badge-pill badge-light"
          }
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.name) }}
        ></span>
      );
    });
    return (
      <div className="container-fluid main-container bg-lined bg-light-gray">
        <div className="box-shadowed pt-1">
          <div className="row bg-white">
            <div className="col-12 blue-line"></div>
          </div>

          <div id="profile-navbar" className="row bg-white text-primary font-weight-bold">
            <div className="col-md-6 nav-cel" onClick={this.goProfile}>
              <div className="d-flex mt-1">
                <span className="hidden-mobile cursor-pointer  arrow-left active left align-self-center"></span>
                <span className="hidden-mobile cursor-pointer  ml-15 fs-22 align-self-center">My profile</span>
              </div>
              <h1 className="text-primary page-title">
                Edit profile
              </h1>
            </div>
            <div className="col-md-6 nav-cel">
              <div className="profile-progress">
                <p>Complete your profile {this.state.progress} %</p>
                <ProgressBar value={this.state.progress} />
              </div>
            </div>
          </div>

          <div className="row bg-gray profile-info">
            <div className="col-md-6">
              <div className="row profile-row">
                <div className="col-md-3">
                  <label htmlFor="basic-url">Name</label>
                  <InputGroup>
                    <FormControl
                      id="user_name_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_name_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <label htmlFor="basic-url">Surname</label>
                  <InputGroup>
                    <FormControl
                      id="user_surname_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_surname_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <label htmlFor="basic-url">Title</label>
                  <InputGroup>
                    <FormControl
                      id="user_title_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_title_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3">
                  <Form.Group controlId="user_gender_lbspa">
                    <Form.Label className="text-black">Gender</Form.Label>
                    <Form.Control
                      as="select"
                      value={
                        this.state.user_gender_lbspa
                          ? this.state.user_gender_lbspa
                          : "Male"
                      }
                      onChange={this.handleChange}
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-4 form-group">
                  <label htmlFor="basic-url">Nationality</label>
                  <InputGroup>
                    <FormControl
                      id="user_nationality_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_nationality_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-4">
                  <Form.Group controlId="user_country_lbspa">
                    <Form.Label className="text-black">Country</Form.Label>
                    <Form.Control
                      as="select"
                      value={
                        this.state.user_country_lbspa
                          ? this.state.user_country_lbspa
                          : "Italy"
                      }
                      onChange={this.handleChange}
                    >
                      <option>British Antilles</option>
                      <option>Afghanistan</option>
                      <option>Albania</option>
                      <option>Algeria</option>
                      <option>American Samoa</option>
                      <option>Andorra</option>
                      <option>Angola</option>
                      <option>Anguilla</option>
                      <option>Antarctic Neozeland Dependencies</option>
                      <option>Antigua And Barbuda</option>
                      <option>Argentina</option>
                      <option>Armenia</option>
                      <option>Australia</option>
                      <option>Australian Dependencies</option>
                      <option>Austria</option>
                      <option>Azerbaijan</option>
                      <option>Bahamas</option>
                      <option>Bahrain</option>
                      <option>Bangladesh</option>
                      <option>Barbados</option>
                      <option>Belarus</option>
                      <option>Belgium</option>
                      <option>Belize</option>
                      <option>Benin</option>
                      <option>Bermuda</option>
                      <option>Bhutan</option>
                      <option>Bolivia</option>
                      <option>Bophuthatswana</option>
                      <option>Bosnia And Herzegovina</option>
                      <option>Botswana</option>
                      <option>Brazil</option>
                      <option>British Dependencies</option>
                      <option>British Virgin Islands</option>
                      <option>Brunei Darussalam</option>
                      <option>Bulgaria</option>
                      <option>Burkina Faso</option>
                      <option>Burma</option>
                      <option>Burundi</option>
                      <option>Cambodia</option>
                      <option>Cameroon</option>
                      <option>Canada</option>
                      <option>Canadian Dependencies</option>
                      <option>Cape Verde</option>
                      <option>Caroline Islands</option>
                      <option>Cayman Islands</option>
                      <option>Central African Republic</option>
                      <option>Chad</option>
                      <option>Chile</option>
                      <option>China</option>
                      <option>Christmas Island</option>
                      <option>Ciskei</option>
                      <option>Cocos (Keeling) Islands</option>
                      <option>Colombia</option>
                      <option>Comore</option>
                      <option>Cook Islands</option>
                      <option>Costa Rica</option>
                      <option>Cote d'Ivoire</option>
                      <option>Croatia</option>
                      <option>Cuba</option>
                      <option>Curaçao</option>
                      <option>Cyprus</option>
                      <option>Czech Republic</option>
                      <option>Czechoslovakia</option>
                      <option>Denmark</option>
                      <option>Djibouti</option>
                      <option>Dominica</option>
                      <option>Dominican Republic</option>
                      <option>Easter island</option>
                      <option>Ecuador</option>
                      <option>Egypt</option>
                      <option>El Salvador</option>
                      <option>Equatorial Guinea</option>
                      <option>Eritrea</option>
                      <option>Estonia</option>
                      <option>Ethiopia</option>
                      <option>Falkland Islands (Malvinas)</option>
                      <option>Faroe Islands</option>
                      <option>Federated States Of Micronesia</option>
                      <option>Fiji Islands</option>
                      <option>Finland</option>
                      <option>France</option>
                      <option>French Dependencies</option>
                      <option>French Guyana</option>
                      <option>French Polynesia</option>
                      <option>French Somalia</option>
                      <option>Gabon</option>
                      <option>Gambia</option>
                      <option>Gaza</option>
                      <option>Georgia</option>
                      <option>Germany</option>
                      <option>Germany democratic republic</option>
                      <option>Ghana</option>
                      <option>Gibraltar</option>
                      <option>Greece</option>
                      <option>Greenland</option>
                      <option>Grenada</option>
                      <option>Guadeloupe</option>
                      <option>Guam</option>
                      <option>Guatemala</option>
                      <option>Guinea</option>
                      <option>Guinea-Bissau</option>
                      <option>Guyana</option>
                      <option>Haiti</option>
                      <option>Honduras</option>
                      <option>Hong Kong</option>
                      <option>Hungary</option>
                      <option>Iceland</option>
                      <option>IFNI</option>
                      <option>India</option>
                      <option>Indonesia</option>
                      <option>Iran, Islamic Republic of</option>
                      <option>Iraq</option>
                      <option>Ireland</option>
                      <option>Isle of Man</option>
                      <option>Israel</option>
                      <option>Italy</option>
                      <option>Jamaica</option>
                      <option>Japan</option>
                      <option>Jordan</option>
                      <option>Kazakhstan</option>
                      <option>Kenya</option>
                      <option>Kingdom of Lesotho</option>
                      <option>Kiribati</option>
                      <option>Kiribati and Tuvalu Countries</option>
                      <option>Kosovo</option>
                      <option>Kuwait</option>
                      <option>Kyrgyzstan</option>
                      <option>La Reunion Island</option>
                      <option>Laos</option>
                      <option>Latvia</option>
                      <option>Lebanon</option>
                      <option>Lesotho</option>
                      <option>Liberia</option>
                      <option>Libya</option>
                      <option>Liechtenstein</option>
                      <option>Lithuania</option>
                      <option>Luxembourg</option>
                      <option>Macao</option>
                      <option>Macquarie Islands</option>
                      <option>Madagascar</option>
                      <option>Malawi</option>
                      <option>Malaysia</option>
                      <option>Maldives</option>
                      <option>Mali</option>
                      <option>Malta</option>
                      <option>Marcus Islands</option>
                      <option>Marshall Islands</option>
                      <option>Martinique</option>
                      <option>Mauritania</option>
                      <option>Mauritius</option>
                      <option>Mayotte</option>
                      <option>Mexico</option>
                      <option>Midway Islands</option>
                      <option>Monaco</option>
                      <option>Mongolia</option>
                      <option>Montenegro</option>
                      <option>Montserrat</option>
                      <option>Morocco</option>
                      <option>Mozambique</option>
                      <option>Nauru</option>
                      <option>Neozeland Dependencies</option>
                      <option>Nepal</option>
                      <option>Netherlands</option>
                      <option>New Caledonia</option>
                      <option>New Guinea</option>
                      <option>New Zealand</option>
                      <option>Nicaragua</option>
                      <option>Niger</option>
                      <option>Nigeria</option>
                      <option>Niue</option>
                      <option>Norfolk Island</option>
                      <option>Norman Islands</option>
                      <option>North Korea</option>
                      <option>North Vietnam</option>
                      <option>Northern Mariana Islands</option>
                      <option>Northern Sahara</option>
                      <option>Norway</option>
                      <option>Norwegian Dependencies</option>
                      <option>Oman</option>
                      <option>Pakistan</option>
                      <option>Palau</option>
                      <option>Palestinian Territory, Occupied</option>
                      <option>Panama</option>
                      <option>Panama Canal Zone</option>
                      <option>Papua</option>
                      <option>Papua New Guinea</option>
                      <option>Paraguay</option>
                      <option>Peru</option>
                      <option>Philippines</option>
                      <option>Pitcairn</option>
                      <option>Poland</option>
                      <option>Portugal</option>
                      <option>Puerto Rico</option>
                      <option>Qatar</option>
                      <option>Republic Of Moldova</option>
                      <option>Republic of Venda</option>
                      <option>Republic of Yemen</option>
                      <option>Romania</option>
                      <option>Russian Dependencies</option>
                      <option>Russian Federation</option>
                      <option>Rwanda</option>
                      <option>Ryukyu Islands</option>
                      <option>Saint Helena, Ascension And Tristan Da Cunha</option>
                      <option>Saint Kitts And Nevis</option>
                      <option>Saint Lucia</option>
                      <option>Saint Pierre And Miquelon</option>
                      <option>Saint Vincent And The Grenadines</option>
                      <option>Samoa</option>
                      <option>San Marino</option>
                      <option>Sao Tome And Principe</option>
                      <option>Saudi Arabia</option>
                      <option>Senegal</option>
                      <option>Serbia</option>
                      <option>Serbia - Montenegro</option>
                      <option>Serbia and Montenegro</option>
                      <option>Seychelles</option>
                      <option>Sierra Leone</option>
                      <option>Singapore</option>
                      <option>Slovakia</option>
                      <option>Slovenia</option>
                      <option>Solomon</option>
                      <option>Solomon Islands</option>
                      <option>Somalia</option>
                      <option>South Africa</option>
                      <option>South African Dependecies</option>
                      <option>South Korea</option>
                      <option>South Sudan</option>
                      <option>South Vietnam</option>
                      <option>Southern Sahara</option>
                      <option>South-West Africa (Republic of Namibia)</option>
                      <option>Spain</option>
                      <option>Spanish Sahara</option>
                      <option>Sri Lanka</option>
                      <option>Sudan</option>
                      <option>Suriname</option>
                      <option>Swaziland</option>
                      <option>Sweden</option>
                      <option>Switzerland</option>
                      <option>Syria</option>
                      <option>Taiwan</option>
                      <option>Tajikistan</option>
                      <option>Tanganyika</option>
                      <option>Tanzania</option>
                      <option>Thailand</option>
                      <option>Congo</option>
                      <option>The Democratic Republic Of The Congo</option>
                      <option>The Former Yugoslav Republic Of Macedonia</option>
                      <option>Timor-Leste</option>
                      <option>Togo</option>
                      <option>Tokelau</option>
                      <option>Tonga</option>
                      <option>Transkei</option>
                      <option>Trinidad And Tobago</option>
                      <option>Tunisia</option>
                      <option>Turkey</option>
                      <option>Turkmenistan</option>
                      <option>Turks And Caicos Islands</option>
                      <option>Tuvalu</option>
                      <option>Uganda</option>
                      <option>Ukraine</option>
                      <option>Union of Soviet Socialist Republic</option>
                      <option>United Arab Emirates</option>
                      <option>United Kingdom of Great Britain and Northern Ireland</option>
                      <option>United States</option>
                      <option>Uruguay</option>
                      <option>Us Dependencies</option>
                      <option>Uzbekistan</option>
                      <option>Vanuatu</option>
                      <option>Vatican City State</option>
                      <option>Venezuela</option>
                      <option>Vietnam</option>
                      <option>Virgin Islands, U.S.</option>
                      <option>Wallis And Futuna</option>
                      <option>Western Irian</option>
                      <option>Yemen</option>
                      <option>Zambia</option>
                      <option>Zanzibar</option>
                      <option>Zimbabwe</option>
                    </Form.Control>
                  </Form.Group>
                </div>

                <div className="col-md-4 text-black">
                  <div className="form-group">
                    <label className="text-black form-label" htmlFor="selectedDate">Birth date</label>
                    <DatePicker
                      selected={this.state.selectedDate}
                      onChange={this.handleDateChange}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <label htmlFor="basic-url">Personal Email</label>
                  <InputGroup>
                    <FormControl
                      id="user_personal_email_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_personal_email_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-6 text-black">
                  <label htmlFor="basic-url">Social Security Number</label>
                  <InputGroup>
                    <FormControl
                      id="user_ssn_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_ssn_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <label htmlFor="basic-url">Region</label>
                  <InputGroup>
                    <FormControl
                      id="user_region_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_region_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-6 text-black">
                  <label htmlFor="basic-url">City</label>
                  <InputGroup>
                    <FormControl
                      id="user_city_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_city_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <label htmlFor="basic-url">Mobile Phone</label>
                  <InputGroup>
                    <FormControl
                      id="user_mobile_number_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_mobile_number_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-6 text-black">
                  <label htmlFor="basic-url">Office Phone</label>
                  <InputGroup>
                    <FormControl
                      id="user_office_number_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_office_number_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row profile-row d-none d-md-block">
                <div className="col-md-12 my-3 text-right">
                  <button type="button" className="btn btn-lg btn-link px-0 text-secondary" onClick={this.updateUser} >
                    Save Changes
                  </button>
                </div>
              </div>

            </div>

            <div className="col-md-6 border-left">

              <div className="row profile-row">
                <div className="col-md-3 col-md-6">
                  <label htmlFor="basic-url">LinkedIn</label>
                  <InputGroup>
                    <FormControl
                      id="user_linkedin_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_linkedin_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>

                <div className="col-md-3 col-md-6 text-black ">
                  <label htmlFor="basic-url">Skype</label>
                  <InputGroup>
                    <FormControl
                      id="user_skype_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_skype_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-3 col-md-6">
                  <label htmlFor="basic-url">Education Degree</label>
                  <InputGroup>
                    <FormControl
                      id="user_educational_degree_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_educational_degree_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="col-md-3 col-md-6 text-black ">
                  <label htmlFor="basic-url">Learning Activities</label>
                  <InputGroup>
                    <FormControl
                      id="user_learning_activities_lbspa"
                      aria-describedby="user-input"
                      type="text"
                      className="form-control opacity width-full text-primary"
                      defaultValue={this.state.user_learning_activities_lbspa}
                      onChange={this.handleChange}
                      onSelect={this.handleChange}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-12">
                  <div className="professional-info">
                    <div className="row mx-2">
                      <h4 className="col-md-12 text-primary mb-3">
                        Professional info
                      </h4>
                      <div className="col-md-4">
                        <Form>
                          <Form.Check
                            custom
                            type="checkbox"
                            id="notEmployed"
                            label="Not employed"
                            checked={this.state.notEmployed}
                            onChange={this.handleNotEmployedChange}
                          />
                        </Form>
                      </div>
                      <div className="col-md-4">
                        {this.state.notEmployed ? (
                          <div className="text-black mb-20 ">
                            <p className="mb-2">Company</p>
                            <p className="mb-2">-</p>
                          </div>
                        ) : (
                          <div>
                            <label htmlFor="basic-url">Company</label>
                                <Autosuggest
                                  theme={autoTheme}
                                  suggestions={this.state.companySuggestion}
                                  onSuggestionsFetchRequested={this.autoOnSuggestionsFetchRequested}
                                  onSuggestionsClearRequested={this.autoOnSuggestionsClearRequested}
                                  getSuggestionValue={this.autoGetSuggestionValue}
                                  renderSuggestion={this.autoRenderSuggestion}
                                  onSuggestionSelected={this.autoOnSuggestionSelected}
                                  inputProps={autoInputProps}
                                />
                           </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        {this.state.notEmployed ? (
                          <div className="text-black">
                            <p className="mb-2">Job title</p>
                            <p className="mb-2">-</p>
                          </div>
                        ) : (
                          <div>

                            <Form.Group controlId="user_job_title_lbspa">
                              <Form.Label className="text-black">Job title</Form.Label>
                              <Form.Control
                                as="select"
                                  value={
                                    this.state.user_job_title_lbspa
                                    ? this.state.user_job_title_lbspa
                                    : ""
                                  }
                                onChange={this.handleChange}
                              >
                                <option>Account Manager</option>
                                <option>Brand Manager</option>
                                <option>Business Development Manager</option>
                                <option>Digital Manager</option>
                                <option>Digital Marketing Manager</option>
                                <option>Engineer Manager</option>
                                <option>Entrepreneur</option>
                                <option>Export Manager</option>
                                <option>Family Business Manager</option>
                                <option>Finance Manager</option>
                                <option>General Manager</option>
                                <option>HR Manager</option>
                                <option>Independent Entrepreneur</option>
                                <option>Lean Manufacturing Manager</option>
                                <option>Legal Manager</option>
                                <option>Manager</option>
                                <option>Manager BU</option>
                                <option>Managing Director</option>
                                <option>Marketing Manager</option>
                                <option>Middle Management</option>
                                <option>Office Manager</option>
                                <option>Operations Manager</option>
                                <option>Organization Manager</option>
                                <option>Owner</option>
                                <option>Product Manager</option>
                                <option>Project Engineer</option>
                                <option>Project Manager</option>
                                <option>Sales Manager</option>
                                <option>Social Media Manager</option>
                                <option>Staff Management</option>
                                <option>Team Lead Manager</option>
                                <option>Team Manage</option>
                              </Form.Control>
                            </Form.Group>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row my-2">
                <div className="col-md-12">
                  <h4 className="mb-3">Topics of interest</h4>
                </div>
                <div className="col-md-12 topics">{topics}</div>
              </div>
            </div>
          </div>
          {/* hide on screens wider than md */}
          <div className="row bg-gray d-md-none">
            <div className="col-md-12 text-right">
              <button type="button" className="btn btn-lg btn-link px-0 text-secondary" onClick={this.updateUser} >
                Save Changes
              </button>
            </div>
            <br/><br/><br/>
          </div>

        </div>
      </div>
    );
  }
}

export default ProfilePageEdit;
