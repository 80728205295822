import { LAYOUT_LOADED } from "../../constants/index";

const initialState = [];

const layoutReducer = (state = initialState, action) => {
  if (action.type === LAYOUT_LOADED ) {
    return action.payload;
  }
  return state;
}

export default layoutReducer;