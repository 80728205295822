import React  from "react";
import DOMPurify from "dompurify";
import { Form } from "react-bootstrap";
import _ from "lodash";
import API from "../utils/API/api";
import ProgressBar from "../utils/progressBar/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";
import "./ProfilePage.scss";

class ProfilePage extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.goToProfile = this.goToProfile.bind(this); 
    this.goToProfileEdit = this.goToProfileEdit.bind(this); 
    this.goHome=this.goHome.bind(this); 
    this.logout=this.logout.bind(this); 
    this.goLogin = this.goLogin.bind(this); 

    this.state = {
      isLoaded: false,
      profileImage: null,
      acf: {},
      id: null,
      user_toi_list: [],
      user_name_lbspa: '-',
      user_surname_lbspa: '-',
      user_title_lbspa: '-',
      user_nationality_lbspa: '-',
      user_country_lbspa: '-',
      user_personal_email_lbspa: '-',
      user_ssn_lbspa: '-',
      user_region_lbspa: '-',
      user_city_lbspa: '-',
      user_mobile_number_lbspa: '-',
      user_office_number_lbspa: '-',
      user_linkedin_lbspa: '-',
      user_skype_lbspa: '-',
      user_educational_degree_lbspa: '-',
      user_learning_activities_lbspa: '-',
      user_not_employed_lbspa: '-',
      user_gender_lbspa: '-',
      user_birthdate_lbspa: '-',
      user_image_lbspa: '-',
      user_company_lbspa: '-',
      user_job_title_lbspa: '-'
    };

    // this.file = new File([''], 'profile-image.png')
  }


  async componentDidMount() {
    try {
      let userMe = await API.get("/wp/v2/users/me");
      userMe = userMe.data;

      let userTopics = (_.has(userMe, "acf.user_toi_list") && !_.isEmpty(userMe.acf.user_toi_list))
        ? userMe.acf.user_toi_list
        : [];
      let acf = {};
      acf = userMe.acf;

      console.log('acf \n', acf);

      let userImage = (_.has(acf, "user_image_lbspa.url") && !_.isEmpty(userMe.acf.user_image_lbspa.url))
        ? acf.user_image_lbspa.url
        : "https://picsum.photos/seed/picsum/160";
      

      /* count how many acf fields are being filled, out of 18, to calculate the progress level */
      let counter = Object.keys(acf);
      
      let realCounter = counter.length;
      if (_.includes(counter, 'user_not_employed_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_not_employed_lbspa');
      }
      if (_.includes(counter, 'user_job_title_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_job_title_lbspa');
      }
      if (_.includes(counter, 'user_company_lbspa')) {
        realCounter--;
        _.remove(counter, key => key === 'user_company_lbspa');
      }
      counter.forEach((key)  => {
        if (acf[key] === '' || acf[key] === null || acf[key] === undefined)
          realCounter--;
      });
      const progress = parseInt(((100 / 19) * realCounter),10);

      this.setState({
        ...this.state,
        ...{
          isLoaded: true,
          progress: progress,
          acf: acf,
          id: userMe.id,
          user_personal_email_lbspa: userMe.acf.user_personal_email_lbspa,
          user_toi_list: userTopics,
          user_name_lbspa: acf.user_name_lbspa,
          user_surname_lbspa: acf.user_surname_lbspa,
          user_title_lbspa: acf.user_title_lbspa,
          user_nationality_lbspa: acf.user_nationality_lbspa,
          user_country_lbspa: acf.user_country_lbspa,
          user_ssn_lbspa: acf.user_ssn_lbspa,
          user_region_lbspa: acf.user_region_lbspa,
          user_city_lbspa: acf.user_city_lbspa,
          user_mobile_number_lbspa: acf.user_mobile_number_lbspa,
          user_office_number_lbspa: acf.user_office_number_lbspa,
          user_linkedin_lbspa: acf.user_linkedin_lbspa,
          user_skype_lbspa: acf.user_skype_lbspa,
          user_educational_degree_lbspa: acf.user_educational_degree_lbspa,
          user_learning_activities_lbspa: acf.user_learning_activities_lbspa,
          user_not_employed_lbspa: acf.user_not_employed_lbspa,
          user_gender_lbspa: acf.user_gender_lbspa,
          user_birthdate_lbspa: acf.user_birthdate_lbspa,
          user_image_lbspa: userImage,
          user_company_lbspa: acf.user_company_lbspa,
          user_job_title_lbspa: acf.user_job_title_lbspa
        }
      });

    } catch (e) {
      console.log("error", e);
    }
  }

  async logout() {

    try {
      let externalWindow = window.open("https://adfs.luiss.it/adfs/ls/?wa=wsignout1.0", '_blank', 'width=600,height=400,left=200,top=200');

      setTimeout(() => {
        
        this.props.changeToken(null);
        API.setToken(null);
        externalWindow.close();
        window.localStorage.removeItem('auth-token');
        this.goLogin();
      },1000);
    } catch (err) {
      console.log(err);
    }
  }

  goToProfile() {
    this.props.goProfileView();
  }
  goHome(){
    this.props.onBack();
  }

  goToProfileEdit() {
    this.props.Edit();
  }

  goLogin() {
    this.props.goToLogin();
  }

  /*async logout() { 
    let respLogout;
    respLogout = await API.get("/lbspa-custom/v1/logout");
    console.log('resp logout: ', respLogout); 
    window.localStorage.removeItem('auth-token');
    this.goLogin(); 
  } */
 
  /*
  goToProfile() { 
    this.props.goProfileView(); 
  } 
  goHome(){ 
    this.props.onBack(); 
  } 
 
  goToProfileEdit() { 
    this.props.Edit(); 
  } 
 
  goLogin() { 
    this.props.goToLogin(); 
  }
  */
 
  handlePhoto = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const img = event.target.files[0];      
      const data = new FormData();
      data.append('file', img);
      let createdMedia = await API.post('/wp/v2/media', data);
      createdMedia = createdMedia.data;        
      let userImage = {
        acf: {
          user_image_lbspa: {
            id: createdMedia.id,
            ID: createdMedia.id,
          }
        }
      }
      let updateUser = await API.post('/acf/v3/users/' + this.state.id, userImage);
      updateUser = updateUser.data;        
      if ((_.has(updateUser, "acf.user_image_lbspa.url") && !_.isEmpty(updateUser.acf.user_image_lbspa.url))) {
        this.setState({
          user_image_lbspa: updateUser.acf.user_image_lbspa.url
        });
      }      
    } catch (error) {
      console.log(error);
    }       
  }
  
  render() {
    const topics = this.state.user_toi_list.map((value, i) => {
      return (
        <span
          key={i.toString()}
          className="badge badge-pill badge-primary"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.name) }}
        ></span>
      );
    });

    return (
      <div className="container-fluid main-container bg-lined bg-light-gray">
        <div className="box-shadowed pt-1">
          <div className="row bg-white">
            <div className="col-12 blue-line"></div>
          </div>

          <div
            id="profile-navbar"
            className="row bg-white text-primary font-weight-bold"
          >
            <div className="col-md-6 nav-cel" onClick={this.goHome}>
              <div className="d-flex mt-1">
                <p className="hidden-mobile cursor-pointer  arrow-left active left align-self-center"></p>
                <p className="hidden-mobile cursor-pointer  ml-15 fs-22 align-self-center">
                  Home
                </p>
              </div>
              <h1 className="text-primary page-title">My profile</h1>
            </div>
            <div className="col-md-6 nav-cel">
              <div className="profile-progress">
                <p>Complete your profile {this.state.progress} %</p>
                <ProgressBar value={this.state.progress} />
              </div>
            </div>
          </div>

          <div className="row bg-gray profile-info">
            <div className="col-md-6 mt-3">
              <div className="row profile-actions">
                <div className="col-sm-6">
                  <img
                    src={this.state.user_image_lbspa}
                    alt="profile"
                    className="profile-image"
                  />
                </div>
                <div className="col-sm-6">
                  
                  <input id="profile-photo" type="file"   ref={(ref) => this.upload = ref} onChange={this.handlePhoto} />

                  <button
                    type="button"
                    className="btn btn-lg btn-link btn-block"
                    onClick={ ()=>{this.upload.click()} }
                  >
                    Change Photo <span className="change-photo active"></span>                    
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn-link btn-block"
                    onClick={this.goToProfileEdit}
                  >
                    Edit Profile <span className="edit-profile active"></span>
                  </button>

                  <button
                    type="button"
                    className="btn btn-lg btn-link btn-block"
                    onClick={this.logout}
                  >
                    Logout <span className="logout active"></span>
                  </button>
                </div>
              </div>
              <div className="row profile-row">
                <div className="col-md-3">
                  <p className="label">Name</p>
                  <p className="value text-primary">
                    {this.state.user_name_lbspa}
                  </p>
                </div>

                <div className="col-md-3">
                  <p className="label">Surname</p>
                  <p className="value text-primary">
                    {this.state.user_surname_lbspa}
                  </p>
                </div>

                <div className="col-md-3">
                  <p className="label">Title</p>
                  <p className="value text-primary">
                    {this.state.user_title_lbspa}
                  </p>
                </div>

                <div className="col-md-3">
                  <p className="label">Gender</p>
                  <p className="value text-primary">
                    {this.state.user_gender_lbspa}
                  </p>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-4 form-group">
                  <p className="label">Nationality</p>
                  <p className="value text-primary">
                    {this.state.user_nationality_lbspa}
                  </p>
                </div>
                <div className="col-md-4">
                  <p className="label">Country</p>
                  <p className="value text-primary">
                    {this.state.user_country_lbspa}
                  </p>
                </div>

                <div className="col-md-4">
                  <p className="label">Birth date</p>
                  <p className="value text-primary">
                    {this.state.user_birthdate_lbspa}
                  </p>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <p className="label">Personal Email</p>
                  <p className="value text-primary">{this.state.user_personal_email_lbspa}</p>
                </div>
                <div className="col-md-6 text-black">
                  <p className="label">Social Security Number</p>
                  <p className="value text-primary">
                    {this.state.user_ssn_lbspa}
                  </p>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <p className="label">Region</p>
                  <p className="value text-primary">
                    {this.state.user_region_lbspa}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="label">City</p>
                  <p className="value text-primary">
                    {this.state.user_city_lbspa}
                  </p>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-6">
                  <p className="label">Mobile Phone</p>
                  <p className="value text-primary">
                    {this.state.user_mobile_number_lbspa}
                  </p>
                </div>
                <div className="col-md-6 text-black">
                  <p className="label">Office Phone</p>
                  <p className="value text-primary">
                    {this.state.user_office_number_lbspa}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 border-left">
              <div className="row profile-row">
                <div className="col-md-3 col-md-6">
                  <p className="label">LinkedIn</p>
                  <p className="value text-primary">
                    {this.state.user_linkedin_lbspa}
                  </p>
                </div>

                <div className="col-md-3 col-md-6 text-black ">
                  <p className="label">Skype</p>
                  <p className="value text-primary">
                    {this.state.user_skype_lbspa}
                  </p>
                </div>
              </div>

              <div className="row profile-row">
                <div className="col-md-3 col-md-6">
                  <p className="label">Education Degree</p>
                  <p className="value text-primary">
                    {this.state.user_educational_degree_lbspa}
                  </p>
                </div>
                <div className="col-md-3 col-md-6 text-black ">
                  <p className="label">Learning Activities</p>
                  <p className="value text-primary">
                    {this.state.user_learning_activities_lbspa}
                  </p>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-12">
                  <div className="professional-info">
                    <div className="row mx-2">
                      <h4 className="col-md-12 text-primary mb-3">
                        Professional info
                      </h4>
                      <div className="col-md-4">
                        <Form readOnly>
                          <Form.Check
                            custom
                            type="checkbox"
                            id="not-employed"
                            label="Not employed"
                            checked={this.state.user_not_employed_lbspa}
                            disabled
                          />
                        </Form>
                      </div>
                      <div className="col-md-4">
                        {this.state.user_not_employed_lbspa ? (
                          <div className="text-black">
                            <p className="mb-2">Company</p>
                            <p className="mb-2">-</p>
                          </div>
                        ) : (
                          <div>
                            <p className="label">Company</p>
                            <p className="value text-primary">
                              {this.state.user_company_lbspa}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="col-md-4">
                        {this.state.user_not_employed_lbspa ? (
                          <div className="text-black">
                            <p className="mb-2">Job title</p>
                            <p className="mb-2">-</p>
                          </div>
                        ) : (
                          <div>
                            <p className="label">Job title</p>
                            <p className="value text-primary">
                              {this.state.user_job_title_lbspa}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div id="topics-of-interest" className="row my-2">
                <div className="col-md-12">
                  <h4 className="mb-3">Topics of interest</h4>
                </div>
                <div className="col-md-12 topics">{topics}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;
