import React from 'react';
import { connect } from "react-redux";
import ProfilePage from '../../components/profilePage/ProfilePage'
import {  setSelected  } from '../../actions/header';



const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setSelected: selected => dispatch(setSelected(selected))
    };
}

class ProfilePageController extends React.Component {

    constructor(props,context){
        super(props,context);

        this.onBack = this.onBack.bind(this);
        this.back=this.back.bind(this);
        this.Edit=this.Edit.bind(this);
        this.goToLogin=this.goToLogin.bind(this);

        this.state = {
            isEditable : false,
            text : 'Mario'
        }
    }
    back(){
        this.props.history.push('/profile');
    }
    Edit(){
        this.props.history.push('/profileEdit');
    }

    onBack(){
        if( this.state.isEditable ){
            this.setState({isEditable:false});
        }else{
            //update the headerstatus (blue line) through redux
            this.props.setSelected(0)
            //come back to home
            this.props.history.push('/');
        }
    }

    goToLogin() {
        this.props.history.push('/login');
    }

    // onSaveChanges(){
    //     this.setState({isEditable:false})
    //     //TODO
    // }

    render(){
        return (
            <ProfilePage    Edit={this.Edit}
                            back={this.back}
                            onBack={this.onBack}
                            goToLogin={this.goToLogin}
                            changeToken={this.props.changeToken}/>
        );
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(ProfilePageController);
