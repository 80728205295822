import React, { Component } from 'react';

class Announcements extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {}
    }
    render() {

        const obj = [
            {
                txt1 : "Lorem ipsum dolor",
                txt2 : "Lorem ipsum dolor sit amet"
            },
            {
                txt1 : "Lorem ipsum dolor",
                txt2 : "Lorem ipsum dolor sit amet"
            }
        ]
        return (
            <div className="container box-shadowed  bg-gray">

                <div className="row bg-white">
                    <div className="col-12 blue-line"></div>
                    <div className="col-6 text-left text-primary pl-20 pt-30 pb-30 fs-26 mt-auto">
                        Posts
                    </div>
                    <div className="col-6 text-center text-primary pl-20 pr-20 pt-30 pb-30 fs-18 my-auto">
                        <div className="text-secondary text-right fs-18 cursor-pointer"> 
                            <strong>Create new </strong> <span className="ml-10 new active right"></span>
                        </div>
                    </div>
                    <div className="col-12 container-announcements bg-gray">
                        {obj.map( (e,index) => {
                                return (
                                    <div key={e.txt2+index.toString()} className="row pt-20 pb-20 bg-light-gray border-bottom cursor-pointer fs-20">
                                        <div className="col-10 pl-20 text-left">
                                            <strong>{e.txt1}</strong><br/>
                                            {e.txt2}</div>
                                        <div className="col-2 pr-20 my-auto">
                                            <span className="arrow-right active right"></span>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }

}

export default Announcements;

