import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import API from "../../utils/API/api";
import './News.scss';


class News extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      news: [],
      hasMore: true,
      page: 1,
      error: {},
      isLoading: false
    };

    this.articles = [];
  }

  loadData = async () => {
    try {
      const response = await API.get("/wp/v2/news", { page: this.state.page, order: 'desc' });

      const total = parseInt(response.headers["x-wp-totalpages"], 10);

      if (total - this.state.page) {
        this.setState({
          news: response.data,
          hasMore: true,
          page: this.state.page + 1
        });
      } else {
        this.setState({
          hasMore: false
        });
      }
    } catch (error) {
      this.setState({
        error: error,
        hasMore: false
      });
    }
  };

  render() {
    this.state.news.forEach(value => {
      /* id */
      const id = (_.has(value, 'id')) ? value.id : 0;
      /* title */
      const title = (_.has(value, 'title.rendered')) ? value.title.rendered : '';
      /* excerpt */
      const excerpt = (_.has(value, 'acf.news_excerpt_lbspa')) ? value.acf.news_excerpt_lbspa : '';
      /* set featured image as background */
      const bgImage = (_.has(value, 'acf.news_featured_image_lbspa.url')) ? ({ backgroundImage: 'url(' + value.acf.news_featured_image_lbspa.url + ')' }) : null;
      /* type */
      const news_type = (_.has(value, 'acf.news_type_lbspa') && value.acf.news_type_lbspa === true) ? 'video' : 'text';
      /* date */
      const date = (_.has(value, "date")) ? value.date : new Date();
      /* categories */
      let categories = [];

      if (_.has(value, "categories_label") && value.categories_label.length ) {
        categories = value.categories_label.map((category, i) => (
          <strong key={i.toString()}> {category} </strong>
        ));
      }
          
      this.articles.push({
          id: id,
          title: title,
          excerpt: excerpt,
          background: bgImage,
          date: new Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit"
          }).format(new Date(date)),
          type: news_type,
          categories: categories,
          views: value.reads_count,
          comments: value.comments_count,
          likes: value.likes_count
        });
    });

    const loader = (
      <div className="row justify-content-center align-items-center" key={0}>
        <div className="spinner-box">
          <div className="solar-system">
            <div className="earth-orbit orbit">
              <div className="planet earth"></div>
              <div className="venus-orbit orbit">
                <div className="planet venus"></div>
                <div className="mercury-orbit orbit">
                  <div className="planet mercury"></div>
                  <div className="sun"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const news = this.articles.map((article, i) => {
      return (
        <div key={i + 1} className="row">
          <React.Fragment>
            <div className="col-12 py-1 text-left fs-14">
              <span> {article.date} | </span>
              <span className="text-primary text-bold"> Category | </span>
              <span className="text-black"> {article.categories} | </span>
            </div>

            <h1 className="col-12 py-1 text-left text-primary">
              <Link to={`/news-details/${article.id}`}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.title) }} />
              </Link>
            </h1>

            <div className="col-12 py-1 text-left">
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.excerpt) }} />
            </div>

            <div className="col-12 py-1">
              {(article.type === 'video') ? (
                <Link to={`/news-details/${article.id}`}>
                  <div className="featured-media" style={article.background}>
                    <span className="video-icon active"></span>
                   </div>
                </Link>
                ) : (
                  <div className="featured-media" style={article.background}>
                  </div>
                )}
            </div>

            <div className="col-12 my-3">
              <div className="row icon-list ">
                <div className="col-3 icon-item"> </div>
                <div className="col-3 icon-item">
                  <span className="eye"></span>
                  <span className="text">{article.views}</span>
                </div>
                <div className="col-3 icon-item">
                  <span className="comments"></span>
                  <span className="text">{article.comments}</span>
                </div>
                <div className="col-3 icon-item">
                  <span className="likes"></span>
                  <span className="text">{article.likes}</span>
                </div>
              </div>
            </div>

            <div className="col-12 pb-5"> </div>
          </React.Fragment>
        </div>
      );
    });

    return (
      <div id="news-container" className="container no-max-width-mobile bg-white">
        <div className="row">
          <div className="col-12 blue-line"></div>
        </div>

        <div className="row">
          <div className="col-12 d-none d-xl-block pt-30 pb-30 text-left">
            <h1 className="text-primary fs-26 mb-0"> News & Events </h1>
          </div>
        </div>

        <InfiniteScroll
            pageStart={0}
            loadMore={this.loadData}
            hasMore={this.state.hasMore}
            loader={loader}
          >
          <div className="justify-content-center align-items-center"> {news} </div>
        </InfiniteScroll>

      </div>
    );
  }
}

export default News;
