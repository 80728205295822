import { SEARCH_RESULTS_LOADED  } from "../../constants/index";

const initialState = {};

const dataReducer = (state = initialState, action) => {
  if (action.type === SEARCH_RESULTS_LOADED ) {
    return action.payload;
  }
  return state;
}

export default dataReducer;