import React from 'react';
import { connect } from "react-redux";
import {  setSelected  } from '../../actions/header';
import SurveysPage from "../../components/surveysPage/SurveysPage";


const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        setSelected: selected => dispatch(setSelected(selected))
    };
}

class SurveysPageController extends React.Component {

    constructor(props,context){
        super(props,context);

        this.onBack = this.onBack.bind(this);



    }

    onBack(){

            //update the headerstatus (blue line) through redux
            this.props.setSelected(0)
            //come back to home
            this.props.history.push('/');
        }




    render(){
        return (
            <SurveysPage onBack={this.onBack}

            />
        );
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(SurveysPageController);
