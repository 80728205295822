import Announcements from '../../../components/home/announcements/Announcements';
import Bullettin from '../../../components/home/bullettin/Bullettin';
import News from '../../../components/home/news/News';
import Profile from '../../../components/home/profile/Profile';
import Surveys from '../../../components/home/surveys/Surveys';

const v4 = {
    header : {},
    body : [
        { name : 'Profile' , component : Profile},
        { name : 'News' , component : News},
        { name : 'Bullettin' , component : Bullettin},
        { name : 'Surveys' , component : Surveys},
        { name : 'Announcements' , component : Announcements}
    ]
}

export default v4;