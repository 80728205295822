import React , { Component }from 'react';


class GoToLuissLearn extends Component{
    constructor(props,context){
        super(props,context);

        this.state = {}
    }
    render(){
        return(
            <div className="bg-secondary cursor-pointer box-shadowed pt-35 pb-35 pl-20 pr-20 color-white text-left fs-26"
                onClick={()=> window.open("https://learn.luiss.it/my/", "_blank")}
            >
                Go to LUISS Learn
                <div className="enter active right"/>
            </div>
        )
    }
    
}

export default GoToLuissLearn;