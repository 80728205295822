import React, { Component } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import InfiniteScroll from 'react-infinite-scroller';
import _ from "lodash";
import API from "../../utils/API/api";
import './surveys.scss';

class Surveys extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoaded: true,
      surveys: [],
      page: 1,
      hasMore: true,      
      error: {},
    };
    this.surveyItems = [];
  }

  loadData = async () => {
    try {
      const response = await API.get("/wp/v2/surveys", { page: this.state.page, order: 'desc' });

      const total = parseInt(response.headers["x-wp-totalpages"], 10);

      let surveyBatch = [];
      surveyBatch = response.data.map(value => {
        /* id  */
        const id = _.has(value, "id") ? value.id : null;
        /* title  */
        const title = _.has(value, "acf.surveys_survey_id_lbspa.post_title") ? value.acf.surveys_survey_id_lbspa.post_title : "";
        /* excerpt  */
        const excerpt = _.has(value, "acf.surveys_excerpt_lbspa") ? value.acf.surveys_excerpt_lbspa : "";
        /* featured image  */
        const image_url = _.has(value, "acf.surveys_featured_image_lbspa.url") ? value.acf.surveys_featured_image_lbspa.url : "";
        const background = { backgroundImage: "url(" + image_url + ")" };

        const survey = {
          id: id,
          title: title,
          excerpt: excerpt,
          background: background
        }
        return survey;
      });
      
      /* check if there's a next page */
      if (total - this.state.page) {
        this.setState({
          isLoading: false,
          surveys: surveyBatch,
          hasMore: true,
          page: this.state.page + 1
        });
      } else {
        this.setState({
          surveys: surveyBatch,
          hasMore: false
        });
      }
    } catch (error) {
      this.setState({
        error: error,
        hasMore: false
      });
    }
  };

  render() {
    
    console.log('surveys', this.surveyItems);

    /* push next batch */    
    this.state.surveys.forEach(survey => {
      this.surveyItems.push(survey);
    });

    // Build an array of survey items
    let surveyList = [];
    surveyList = this.surveyItems.map((value, i) => {
      return (
        <div className="col-sm-12 bg-light-gray text-primary border-bottom my-2" key={i}>
          <h3 className="font-weight-bold bg-light-gray text-primary text-left" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.title) }} />
          <div className="text-black text-left" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.excerpt) }} />
          <div className="py-3 text-right">
            <Link className="btn btn-secondary width-90 height-30 border-radius-50 line-height-20 fs-18" to={`/surveys-details/${value.id}`} >
              TAKE
            </Link>
          </div>
        </div>
      );
    });

    const loader = (
      <div className="spinner-box" key={'spinner-survey'}>
        <div className="solar-system">
          <div className="earth-orbit orbit">
            <div className="planet earth"></div>
            <div className="venus-orbit orbit">
              <div className="planet venus"></div>
              <div className="mercury-orbit orbit">
                <div className="planet mercury"></div>
                <div className="sun"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    console.log('surveys', this.state.surveys);
    console.log('surveyList', surveyList);

    return (
      <div className="container box-shadowed bg-light-gray">
        <div className="row">
          <div className="col-12 blue-line"></div>
          <div className="col-6 text-left text-primary pl-20 pt-30 pb-30 fs-26 mt-auto">
            Surveys
          </div>
          <div onClick={() => this.props.onGoTo(3)} className="col-6 text-center text-primary pl-20 pr-20 pt-30 pb-30 fs-20 mt-auto" >
            <div className="text-secondary text-right fs-15 cursor-pointer">
              <span className="open active right"></span>
            </div>
          </div>
        </div>
        <div id="surveys-lazy-loading" className="row">
          <div className="col-12">
            <InfiniteScroll
              pageStart={1}
              loadMore={this.loadData}
              hasMore={this.state.hasMore}
              loader={loader}
            >
              <div className="row no-gutters"> {surveyList} </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

export default Surveys;
